/**
 * 大数据埋点系统
 **/
import store from '@/store/index';
import utils from '@/utils/common';
import config from '@/config/user.env';

class BigDataLog {
    constructor() {
        this.mainApi = config.bigDataLogUrl + '/api/2.0/send_main_message';
        this.detailApi = config.bigDataLogUrl + '/api/2.0/send_detail_message';
        this.moduleName = '';
        this.childModuleName = '';
        this.userName = '';
        this.browserInfo = {};
        this.screenInfo = {};
    }

    init(moduleName, childModuleName) {
        this.moduleName = moduleName;
        this.childModuleName = childModuleName;
        this.browserInfo = this.getBrowserInfo();
        this.screenInfo = this.getScreenInfo();
    }
    getBrowserInfo() {
        const Sys = {
            platform: window.navigator.platform,
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            appPlatform: window.navigator.appVersion,
            appVersion: window.navigator.buildID
        };
        const ua = window.navigator.userAgent.toLowerCase();
        const re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
        const m = ua.match(re);
        if (m[0] && m[1]) {
            Sys.appCodeName = m[1].replace(/version/, "'safari");
            Sys.appName = m[0];
        }
        return Sys;
    }
    getScreenInfo() {
        return {
            availWidth: window.screen.availWidth,
            availHeight: window.screen.availHeight,
            width: window.screen.width,
            height: window.screen.height,
            colorDepth: window.screen.colorDepth,
            pixelDepth: window.screen.pixelDepth
        };
    }
    getNetworkType() {
        let netType = 'wifi';
        if (window.navigator.connection && window.navigator.connection.effectiveType) {
            netType = navigator.connection.effectiveType;
        }
        return netType;
    }
    getMainMessage() {
        return {
            userName: this.userName,
            moduleName: this.moduleName,
            childModuleName: this.childModuleName,
            clientDate: utils.formatDateTime(new Date()),
            href: window.location.href,
            ...this.screenInfo,
            ...this.browserInfo
        };
    }
    getDetailMessage(modelInfo = {}) {
        return {
            userName: this.userName,
            moduleName: this.moduleName,
            childModuleName: this.childModuleName,
            clientDate: utils.formatDateTime(new Date()),
            href: window.location.href,
            ...this.screenInfo,
            ...this.browserInfo,
            ...modelInfo
        };
    }
    sendMainMessage() {
        // 必填信息不为空才提交
        if (!this.moduleName) {
            return false;
        }
        // 注意 userCode 在 store 存放的位置
        this.userName = store.state.user.user.userCode;
        utils.ajax({
            url: this.mainApi,
            data: {
                appName: config.bigDataLogAppName,
                topicName: 'request_data_main_log',
                message: JSON.stringify(this.getMainMessage())
            },
            type: 'POST',
            success: function(data) {}
        });
    }
    sendDetailMessage(modelInfo = {}) {
        // 必填信息不为空才提交
        if (!this.moduleName || !modelInfo.model_name1) {
            return false;
        }
        utils.ajax({
            url: this.detailApi,
            data: {
                appName: config.bigDataLogAppName,
                topicName: 'request_data_detail_log',
                message: JSON.stringify(this.getDetailMessage(modelInfo))
            },
            type: 'POST',
            success: function(data) {}
        });
    }
    sendDetailMessageByKey(key) {
        let names = window.bigDataConfig[key];
        // 匹配不到埋点数据取消请求
        if (!names) {
            return false;
        }
        const params = {};
        if (typeof names === 'string') {
            names = names.split(',');
        }
        names.forEach((name, index) => {
            params['model_name' + (index + 1)] = name;
        });
        this.sendDetailMessage(params);
    }
    sendDetailMessageOfRoute(fullPath) {
        /* eslint-disable */
        // console.log('store.getters.menus', store.getters.menus);
        // console.log('fullPath', fullPath);
        const PAGE_TYPE = 2; // 统一权限 resourceType, 2指的是页面
        let model_name1 = '';
        let model_name2 = '';
        let matched = false;

        store.getters.menus.forEach(item => {
            if (item.resourceType === PAGE_TYPE) {
                item.frontUrl === fullPath && (model_name1 = item.resourceName);
                matched = true;
            } else {
                const matchedSubItem = item.subResources.find(subItem => subItem.resourceType === PAGE_TYPE && subItem.frontUrl === fullPath);
                if (matchedSubItem) {
                    model_name1 = item.resourceName;
                    model_name2 = matchedSubItem.resourceName;
                    matched = true;
                    // console.log(model_name1, model_name2)
                    // console.log(model_name1, model_name2)
                }
            }
        });
        if (matched) {
            this.sendDetailMessage({ model_name1, model_name2 });
        }
        /* eslint-enable */
    }
    sendErrorMessage(errInfo = {}) { }
}
export default new BigDataLog();

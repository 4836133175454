import { dict } from './api';
class DictQueue {
    fetch = () => { throw Error('fetch 函数不能为空'); };
    queue = new Map();
    timer = null;
    // eslint-disable-next-line no-magic-numbers
    delay = 500;
    constructor(fetch) {
        this.fetch = fetch;
    }

    setFetchFn(fetch) {
        this.fetch = fetch;
    }

    queueUp(codes, url) {
        if (!codes) return;
        const promise = new Promise(resolve => {
            this.queue.set(resolve, { codes, url });
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(this.fetchDicts.bind(this), this.delay);
        return promise;
    }

    async fetchDicts() {
        /**
         * urlMapQueue:{
         *     'urlAA'：['dict1','dict2'],
         *     'urlBB'：['ENABLE','ORDER_TYPE'],
         * }
         */
        const urlMapQueue = [...this.queue.values()].reduce((dictGroupByUrl, { codes, url }) => {
            if (!dictGroupByUrl[url]) {
                dictGroupByUrl[url] = [];
            }
            const codesArr = codes.split(',');
            dictGroupByUrl[url] = [...new Set([...dictGroupByUrl[url], ...codesArr])];
            return dictGroupByUrl;
        }, {});
        // 将所有不同url的字典映射成Promise请求
        const loadAllURLDict = Object.keys(urlMapQueue).map((url) => {
            return dict(url, urlMapQueue[url].join(',')).then(res => res);
        });
        Promise.all(loadAllURLDict).then(res => {
            /**
             * allDictResult:{
             *     'urlAA'：['dict1':{ }, 'dict2':{ } ],
             *     'urlBB'：['ENABLE':{ }, 'ORDER_TYPE':{ } ] ,
             * }
             */
            const allDictResult = res.reduce((previousValue, oneRs, index) => {
                previousValue[Object.keys(urlMapQueue)[index]] = oneRs.data;
                return previousValue;
            }, {});
            // 调用各自的resolve
            this.queue.forEach(({ codes, url }, resolve) => {
                /**
                 * resp:{
                 *     code:0,
                 *     data:{
                 *          ENABLE:{ 1:'XXX',2:'YYY' },
                 *          ORDER_TYPE:{ 1:'XXX',2:'YYY' }
                 *     }
                 * }
                 */
                const resp = codes.split(',').reduce((result, code) => {
                    const dataInURL = allDictResult[url];
                    result.data[code] = dataInURL[code] || {};
                    return result;
                }, {
                    code: '0',
                    data: {}
                });
                resolve(resp);
            });
        });
    }
}

export default DictQueue;

<template>
    <!-- :classify="classify" G5000升级取消传参 -->
    <feed-back
        :env="env"
        :lang="lang"
        v-if="userInfo.token"
        :user-info="userInfo"
        :component-bottom="componentBottom"
        ref="feedbackRef"
        :intimeevaluate-user-info="intimeevaluateUserInfo"
        :intimeevaluate-templete-info="intimeevaluateTempleteInfo"
        :tabs="tabs"
        :whether-the-coexistence="whetherTheCoexistence"
        :theme-color="themeColor"
        :tabs-background-color="tabsBackgroundColor"
        :unfold-color="unfoldColor"
        :config-info="configInfo"
        :is-screenshots="isScreenshots"
        :helpInfo="helpInfo"
    />
</template>

<script>
import FeedBack from 'itsm-feedback-v2';
import { mapGetters } from 'vuex';
import config from '@/config/user.env';
import { getItsmToken } from '@/api/userApi';
const env = config.env ? config.env.toLowerCase() : 'sit';
export default {
    components: { FeedBack },
    computed: {
        ...mapGetters(['user', 'visitedViews'])
    },
    // eslint-disable-next-line max-lines-per-function
    data() {
        return {
            // 新IT5000组件接入参数配置start
            env: env === 'ver' ? 'uat' : env, // "sit"，"uat"，"prod"  三种环境，prod是生产环境
            lang: 'zh-CN', // 国际化语言：zh-CN | en-US | ja-JP
            // G5000升级注释掉：废弃手工配置事件分类，由组件内部自动获取
            // classify: {
            //     oneClassifyCode: 'yyyw', // 填写自己项目的一级分类 如：jcjg
            //     twoClassifyCode: env !== 'prod' ? 'yyyw_a55' // (sit/uat) 填写自己项目的二级分类 如：jcjg_ywxtgl
            //         : 'yyyw_prm01', // prod
            //     threeClassifyCode: env !== 'prod' ? 'yyyw_a55_a3' // (sit/uat) 填写自己项目的三级分类 如：jcjg_ywxtgl_3
            //         : '5930ab8233714f2796ad2d64d021390f' // prod
            // },
            // // classify: 'yyyw_a55_a3', // 填写自己项目的三级分类（没三级就填写二级），classify属性的string值和上面的object值 两种方式选一种写
            userInfo: {
                user: '', // mip账号
                token: '', // 传入token可访问外网
                productId: env !== 'prod' ? '21049477-4a59-4cb8-b88c-583251c3f8c7' // 填写自己项目的产品id (sit/uat)
                    : '3daf8567-c14d-4c65-a0d2-5ff85bf4117f', // (prod)
                systemId: env !== 'prod' ? '03077afb-9e7b-407f-94c9-31ef73c9fbdd' // 填写自己项目的系统id (sit/uat/prod)
                    : '2b592997-3a3f-4473-b3f5-f0c910184bcf', // prod
                appId: env !== 'prod' ? '1c81090ccaa8465e8277a07666c0f0e0' : '2e92a357a9474368b465ffd4ac9793a1', // prod
                productName: 'LCRM物流客户关系管理系统', // 填写自己项目的产品的中文 例: 'UOP用户运营产品'
                systemName: env !== 'prod' ? 'C-AWS' : 'C-LCRM', // 填写自己项目的系统的中文 例： 'F-ITSM-C'
                commonFieldList: [
                    // { name: '公共字段1', value: '111' },
                ] // 富文本的开头文字（可以不填）----- 提交it5000会回显到富文本中 富文本里的内容会以 【测试添加头部信息】+ 富文本内容 的方式提交
            },
            componentBottom: 200, // 调整组件底部距离，(有默认值可以不传)
            intimeevaluateUserInfo: {
                // eslint-disable-next-line camelcase
                user_name: '', // mip账号（姓名）
                // eslint-disable-next-line camelcase
                user_id: '' // mip账号
            },
            intimeevaluateTempleteInfo: { // 需要接入我的评价才填写，如需接入，让产品找吴吉允填写即使评价问卷获取配置信息
                terminal: 'PC',
                appType: 'pc', // 终端类型 pc、app
                modeType: 'hover', // 弹框挂件展示类型 hover: 挂件式(悬浮右下角) inner:嵌入  默认:''不展示任何挂件
                business: '安得智联', // 领域 eg: 内销领域
                system: 'C-LCRM', // 系统 eg: 美云销
                product: 'LCRM物流客户关系管理系统', // 产品 eg: TPC交易与履约中心
                scene: 'LCRM系统-首页右侧', // 投放位置, 挂件式一般放页面右下角，eg: 人才搜索右侧 唯一标示 测试生产环境要对上
                primaryColor: '#00BCBF', // 提交按钮的主色调,默认：#0888ff
                hoverBtnColor: '', // 提交按钮在hover时候的颜色， 默认：#39a0ff
                hoverTagColor: '' // tag标签在hover时候的颜色 默认：#ebf5ff
            },
            tabs: [ // 菜单名称
                {
                    unique: 'G5000',
                    name: 'IT5000',
                    icon: 'feedback-tool-filled',
                    jumpUrl: ''
                },
                {
                    unique: 'comment',
                    name: '我要评价',
                    icon: '', // 我的评价静态icon为icon-chat-line-square-filled，为空则显示动图gif
                    jumpUrl: ''
                },
                {
                    unique: 'advice',
                    name: '我要建议',
                    icon: 'feedback-edit-filledbeifen',
                    jumpUrl: ''
                },
                {
                    unique: 'help',
                    name: '帮助中心',
                    nameEn: 'Help center',
                    nameJa: 'ヘルプセンター',
                    icon: 'icon-question'
                }
            ],
            whetherTheCoexistence: true, // 我要反馈与我要评价是否共存
            themeColor: '#0077ff', // 主题颜色
            tabsBackgroundColor: '#fff', // tabs背景色
            unfoldColor: '#0077ff', // 展开按钮颜色
            configInfo: {
                mode: null, // 1:GPM系统,2:GSC系统,3:CSS系统,不传和为空都是默认模式
                unfoldIcon: 'feedback-edit-filled',
                version: 1, // 金融版本为1
                channelCode: 'CLCRMsystem',
                isDrag: true, // 是否拖拽
                justHangTabs: false, // 是否只挂载tabs
                isShowJumpBtn: true,
                isExtranet: true
            },
            isScreenshots: true, // 控制截图功能
            helpInfo: { // 帮助中心信息
                systemName: 'C-LCRM', // 系统名称
                relateId: '0' // 文档id
            }
            // end
        };
    },
    watch: {
        '$route': {
            handler(route) {
                this.updateConf(route);
            },
            immediate: true
        }
    },
    created() {
        this.userInfo.user = this.user.userCode;
        this.intimeevaluateUserInfo.user_name = this.user.userName;
        this.intimeevaluateUserInfo.user_id = this.user.userCode;
        getItsmToken().then(res => {
            if (+res.code === 0 && res.data) {
                this.userInfo.token = res.data;
            }
        });
    },
    methods: {
        updateConf(route) {
            this.$nextTick(() => {
                if (this.visitedViews.length) {
                    const currentResource = this.visitedViews.find(item => {
                        return item.path === route.path;
                    });
                    if (currentResource) {
                        this.helpInfo.relateId = String(currentResource.resourceCode || '');
                    }
                }
            });
        }
    }
};

</script>

<style lang="less">
</style>

<template>
    <div class="header-info flex-container">
        <div class="tags-view">
            <tags-view></tags-view>
        </div>
        <div class="header-icon-button">
            <Tenant/>
            <Msg v-if="showMsg" />
            <Question/>
            <el-dropdown @visible-change="visibleChange">
                <el-button type="text" class="icon iconfont icon-button icon-fixed-size">&#xe6ba;</el-button>
                <file-list  ref="uploadlist"></file-list>
            </el-dropdown>
            <!-- <Comment /> -->
            <App />
            <!--<comp-toggle systemCode="lang"><Lang/></comp-toggle>-->
            <Logout/>
        </div>
    </div>
</template>
<script>
import TagsView from './TagsView';
import Tenant from './themes/Tenant';
import Question from './themes/Question';
import Msg from './themes/Msg';
import Logout from './themes/Logout';
// import Comment from './themes/comment/index';
// import Lang from './themes/Lang';
import fileList from './themes/fileList/UploadList';
import App from './themes/app/Index';
// import compToggle from '@/components/lots/compToggle';

export default {
    components: { TagsView, Tenant, Msg, Question, Logout, fileList, App },
    props: {
        showMsg: { // 是否开启消息通知组件
            type: Boolean,
            default: true
        }
    },
    methods: {
        visibleChange() {
            this.$refs.uploadlist.refresh();
        }
    }
};

</script>
<style lang="less">
.header-info {
    .icon-fixed-size{
        font-size: 14px;
    }
    height: 50px;
    align-items: center;

    .tags-view {
        flex: 1;
        padding-left: 20px;
    }
    .header-icon-button {
        white-space: nowrap;
        overflow: auto;
        .user-name {
            color: #333;
            padding: 16px 20px;
            &:hover {
                color: #4285f5;
            }
        }
    }
    .icon-button {
        width: 50px;
        text-align: center;
        height: 50px;
        margin-left: 0;
        color: #333;

        &:hover {
            color: #4285f5;
        }
    }
}

.userInfo {
    padding-bottom: 15px;
    .avatar {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: skyblue;
        line-height: 30px;
        text-align: center;
        margin-right: 5px;
    }
}

.tenantText {
    display: inline-block;
    font-size: 12px;
    color: #fff;
    padding: 5px 0 0 15px;
}

.el-dropdown-menu {
    padding: 5px 0;

}
</style>

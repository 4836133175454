<template>
    <el-button @click="handleTenantCommand" type="text" icon="iconfont icon-topbar_changeline_medium icon-fixed-size" class="user-name">
        {{currentTenant.tenantName}}
        <el-dialog
            :title="$translate('lang.E202006160001',{defaultText:'切换租户'})"
            :visible.sync="TenantVisible"
            width="500px"
            modal-append-to-body
            append-to-body
            top="7%"
            class="tenant-dialog"
            @close="closeDialog">
            <div>
                <div class="tenant-top">
                    <span>{{$translate('lang.E1202104124666',{defaultText:'当前'})}}：{{this.currentTenant.tenantName}}</span>
                    <div>
                        <el-input
                            :placeholder="$translate('lang.E202006160003', { defaultText: '请输入租户编码/名称' })"
                            v-model="searchTenant"
                            size="mini"
                            @keyup.enter.native="_getList"
                            class="search-tenant-input">
                        </el-input>
                        <el-button type="primary" size="mini" @click="_getList">{{$translate('lang.E202006152369',{defaultText:'查询'})}}</el-button>
                    </div>
                </div>
                <div class="tenant-list">
                    <div v-for="(item, index) in tenantDialog.tenantsList"
                         :key="index"
                         :class="{'tenant-item': true, 'isActivt': item.tenantCode === currentTenant.tenantCode}"
                         @click="() => { handleActive(item) }">
                        <span>{{`${item.tenantName} | ${item.tenantCode}`}}</span>
                        <div v-if="item.tenantCode === currentTenant.tenantCode" class="active-item"></div>
                    </div>
                </div>
            </div>
            <el-pagination
                class="text-right"
                background
                small
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="tenantDialog.currentPage"
                :page-sizes="[10, 20, 30, 50, 100]"
                layout='prev, pager, next'
                :total="tenantDialog.total">
            </el-pagination>
        </el-dialog>
    </el-button>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { I18nMixins } from '@/components/mixins/i18n';

export default {
    mixins: [I18nMixins],
    data() {
        return {
            TenantVisible: false,
            searchTenant: ''
        };
    },
    computed: {
        messageCount() {
            return this.warningListTotal;
        },
        warningDisabled() {
            return this.warningHasMore || this.warningLoading;
        },
        noticeDisabled() {
            return this.noticeHasMore || this.noticeLoading;
        },
        ...mapGetters(['currentTenant', 'tenantDialog'])
    },
    methods: {
        handleTenantCommand(command) {
            this._getList();
            this.TenantVisible = true;
        },
        // 获取租户列表
        _getList(data = {}) {
            this.getAllTenants({
                ...this.tenantDialog,
                tenantCodeOrName: this.searchTenant,
                ...data
            });
        },
        // 设置租户
        _setTenant(data) {
            this.settingTenant(data).then(res => {
                if (res && res.data) {
                    this.$router.go(0);
                }
            });
        },
        // 关闭租户弹窗
        closeDialog() {
            this.TenantVisible = false;
            this.searchTenant = '';
            this.updateTenantDialog({
                ...this.tenantDialog,
                currentPage: 1,
                total: 0,
                pageSize: 10,
                tenantsList: []
            });
        },
        // 切换每页数量
        handleSizeChange(val) {
            this._getList({ pageSize: val });
        },
        // 切换页面
        handleCurrentChange(val) {
            this._getList({ currentPage: val });
        },
        // 切换租户
        handleActive(item) {
            this._setTenant(item);
        },
        ...mapMutations({
            updateTenantDialog: 'updateTenantDialog'
        }),
        ...mapActions([
            'getAllTenants',
            'settingTenant'
        ])
    }
};
</script>

<style lang="less">
.tenant-dialog{
    text-align: left;
    .tenant-top{
        display: flex;
        justify-content: space-between;
    }
    .search-tenant-input{
        width: 160px;
        margin-right: 10px;
    }
    .tenant-list{
        height: 300px;
        overflow-y: auto;
        border: 1px solid rgba(233, 233, 233, 1);
        margin-top: 10px;
    }
    .tenant-item{
        padding: 0 10px;
        line-height: 40px;
        border-bottom: 1px solid rgba(233, 233, 233, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:hover{
            background-color: #F5F7FA;
        }
    }
    .isActivt{
        background-color: #E1E7EF;
    }
    .active-item{
        width: 5px;
        height: 10px;
        border-bottom: 2px solid #409EFF;
        border-right: 2px solid #409EFF;
        transform: rotate(45deg);
        margin: 5px 10px 10px 0;
    }
    .text-right{
        margin: 10px 0;
    }
    .dialog-title {
        margin-right: 5px;
        font-size: 14px;
    }
    .dialog-title-button {
        &:hover, &:active, &:focus {
            border-color: transparent;
        }
    }
    .el-dialog__body {
        padding: 20px;
    }
}
</style>

import Vue from 'vue';
import 'normalize.css';
import ElementUI from 'element-ui';
import BaiduMap from 'vue-baidu-map';
import 'element-ui/lib/theme-chalk/index.css';
import './style/base.css';
// import './theme/index.css';
import App from './App';
import { checkLogin } from './utils/index';
import router from './router';
import store from './store';
import './iconfont/iconfont.css';
import './utils/btnPermission';
import { initKeepAliveCfg } from './utils/keepAliveCfg.js';
import './utils/lcpMessage.js';
import bigDataLog from '@/utils/bigDataLog';
import VueCompositionApi from '@vue/composition-api';
import { I18n, $translate } from '@/utils/i18n';
import './public-path';
import Print from 'vue-print-nb';
import config from '@/config/user.env';
// 一体化监控平台
import Cookies from 'js-cookie';
import { init } from '@aimitojs/browser';
import { vuePlugin } from '@aimitojs/vue';

init({
    vue: Vue,
    appKey: config.appKey,
    serviceName: config.serviceName,
    dsn: config.dsn,
    backUserId () {
        // 比如userId为放在Cookies中的集团MIP账号
        return Cookies.get('apmUser') || '';
    }
}, [vuePlugin]);
Vue.use(Print);
// 测试
// import Mock from './test/index';
Vue.use(VueCompositionApi);
Vue.use(BaiduMap, {
    ak: 'uXRnW5KnsRHsIseRVNRrAvtnBWd2sVHR'
});
Vue.mixin({
    methods: {
        // 用于处理语言包不存在的时候 使用缺省值2s
        $translate (key, params) {
            return $translate;
        }
    }
});
Vue.use(ElementUI, { size: 'mini' });
let oldPath = null;
if (window.__MICRO_APP_ENVIRONMENT__) {
    // 监听基座下发的数据变化
    window.microApp.addDataListener((data) => {
        // 当基座下发跳转指令时进行跳转
        if (data.path && data.path !== oldPath) {
            oldPath = data.path;
            router.push(data.path);
        }
    });
}
(async function () {
    await checkLogin();
    const moduleName = 'C-LCRM';
    const childModuleName = '物流客户关系管理';
    bigDataLog.init(moduleName, childModuleName);
    initKeepAliveCfg();
    store.dispatch('UPDATE_LANG').then(langData => {
        const i18n = I18n();
        Vue.use(ElementUI, {
            i18n: (path, options) => {
                const value = i18n.t(path, options);
                if (value) return value;
                return '';
            }
        });

        new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app');
    });
})();

// 大数据埋点-应用功能明细
document.body.addEventListener('click', function (ev) {
    let target = ev.target;
    while (target.tagName.toLowerCase() !== 'body') {
        if (!target.className) {
            target = target.parentNode;
            if (!target) {
                break;
            } else {
                continue;
            }
        }
        if (target.className && target.className.indexOf('bigData-btn-') < 0) {
            target = target.parentNode;
            // 无父级target时即停止循环，防止报错
            if (!target) {
                break;
            }
        } else {
            const params = {};
            const key = target.className.split(' ').find(li => li.indexOf('bigData-btn-') >= 0);
            let names = window.bigDataConfig[key];
            if (!names) {
                break;
            }
            if (typeof names === 'string') {
                names = names.split(',');
            }
            names.forEach((name, index) => {
                params['model_name' + (index + 1)] = name;
            });
            bigDataLog.sendDetailMessage(params);
            break;
        }
    }
});

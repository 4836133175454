import {
    findCurrUser,
    getUserAllTenants,
    getAllResources,
    querySupplierInFoBySupplierCode
    // fetchSystemSwitch
} from '@/api/userApi';
import lang from '@/lang/index';
import config from '@/config/user.env';
import { lcpMessage as Message } from '@/components/lots/lcpMessage.js';
import { alertErrorAndLogout, checkAndAlertError } from '@/utils/index.js';
import uploadList from './themes/UploadList';
import tenant from './themes/Tenant';
import Msg from './themes/Msg';
import App from './themes/App';
import cookies from 'js-cookie';
function getResourceIds (v) {
    const RESOURCE_TYPE_MENU = 3;
    const tempArr = [];
    getId(v);

    function getId (v) {
        v.forEach(item => {
            if (+item.resourceType === RESOURCE_TYPE_MENU && item.resourceId) {
                tempArr.push(item.resourceId);
            } else if (item.subResources) {
                getId(item.subResources);
            }
        });
    }
    return tempArr;
}

export default {
    state: {
        user: {},
        authority: '',
        appId: config.appId,
        lang: {},
        tenantInfo: {},
        currentTenant: {},
        currentApplicationCode: '',
        btnPermissions: null,
        supplier: {},
        locale: '',
        langInfo: {},
        switchs: [],
        vue2MicroRoot: '',
        lotsUIEventInstance: '',
        contractManageParams: {}, // 合同-其他变更代办参数
        ...tenant.state,
        ...Msg.state,
        ...uploadList.state,
        ...App.state
    },
    getters: {
        user: state => state.user,
        appId: state => state.appId,
        authority: state => state.authority,
        menus: state => {
            if (state.authority) {
                return state.authority;
            }
            return [];
        },
        lang: state => state.lang,
        tenantInfo: state => state.tenantInfo,
        currentTenant: state => state.currentTenant,
        btnPermissions: state => state.btnPermissions,
        applicationCode: state => {
            const currentPath = state.appList.find(item => {
                if (item.applicationDomain) {
                    return item.applicationDomain.indexOf(window.location.pathname) >= 0;
                }
                return false;
            });
            return currentPath ? currentPath.applicationCode : '';
        },
        supplier: state => state.supplier,
        locale: state => state.locale,
        langInfo: state => state.langInfo,
        systemSwitchs: state => state.switchs,
        vue2MicroRoot: state => state.vue2MicroRoot,
        lotsUIEventInstance: state => state.lotsUIEventInstance,
        contractManageParams: state => state.contractManageParams,
        ...tenant.getters,
        ...Msg.getters,
        ...uploadList.getters,
        ...App.getters
    },
    mutations: {
        getBtnPermissions (state, btnPermissions) {
            state.btnPermissions = btnPermissions;
        },
        updateUserInfo (state, user) {
            state.user = user;
        },
        updateAuthority (state, authority) {
            state.authority = authority;
        },
        updateLang (state, _lang) {
            state.lang = _lang;
        },
        updateTenant (state, _tenantInfo) {
            state.tenantInfo = _tenantInfo;
        },
        updateCurrenTenant (state, _tenantInfo) {
            state.currentTenant = _tenantInfo;
        },
        updateCurrentApplicationCode (state, applicationCode) {
            state.currentApplicationCode = applicationCode;
        },
        updateSupplier (state, supplier) {
            state.supplier = supplier;
        },
        updateLangInfo (state, LangInfo) {
            state.langInfo = LangInfo;
        },
        updateLocale (state, locale) {
            // 设置接口的cookie
            cookies.set('lang', locale, { domain: '.midea.com' });
            // 设置时区
            var d = new Date();
            var gmtHours = d.getTimezoneOffset() / 60;
            gmtHours = -gmtHours;
            let sign = '+';
            if (gmtHours < 0) {
                sign = '-';
            }
            const numLength = 2; // 数字长度 前面补0
            gmtHours = (Array(numLength).join(0) + gmtHours).slice(-numLength);
            cookies.set('timezone', 'GMT' + sign + gmtHours + ':00', { domain: '.midea.com' });
            state.locale = locale;
        },
        getSystemSwitchs (state, switchs) {
            state.switchs = switchs;
        },
        getVue2MicroRoot (state, vue2MicroRoot) {
            state.vue2MicroRoot = vue2MicroRoot;
        },
        getLotsUIEventInstance (state, lotsUIEventInstance) {
            state.lotsUIEventInstance = lotsUIEventInstance;
        },
        getContractManageParams (state, contractManageParams) {
            state.contractManageParams = contractManageParams;
        },
        ...tenant.mutations,
        ...Msg.mutations,
        ...uploadList.mutations,
        ...App.mutations
    },
    actions: {
        /**
         * 初始化系统
         * @param {*} param0
         */

        initTenantsData ({ dispatch, commit, state }) {
            const params = {
                listFlag: 1,
                pageNo: 1,
                pageSize: 10,
                syncTenant: 1
            };
            return Promise.all([findCurrUser(), getUserAllTenants(params)]).then(res => {
                if (+res[0].code === 0 && +res[1].code === 0) {
                    commit('updateUserInfo', res[0].data);
                    if (!res[1].data.currentTenant) {
                        !window.location.href.includes('/mipIframe?') && alertErrorAndLogout('不存在默认租户，请联系管理员重新登录!');
                        return false;
                    }
                    commit('updateTenant', res[1].data.tenants ? res[1].data.tenants : {});
                    commit('updateCurrenTenant', res[1].data.currentTenant ? res[1].data.currentTenant : {});
                    return dispatch('initResources');
                } else {
                    checkAndAlertError(res[0]);
                    checkAndAlertError(res[1]);
                }
            });
        },

        initMicroSystem ({ commit }) {
            const data = window.microApp.getData();
            commit('updateUserInfo', data.user);
            commit('updateTenant', data.tenantInfo);
            commit('updateCurrenTenant', data.currentTenant);
            commit('updateAppList', data.appList);
            commit('updateCurrentApplicationCode', data.applicationCode);
            commit('getBtnPermissions', data.btnPermissions);
            commit('updateAuthority', data.authority);
            commit('getVue2MicroRoot', data.vue2MicroRoot);
            commit('getLotsUIEventInstance', data.lotsUIEventInstance);
        },

        initResources ({ dispatch, commit, state }) {
            const applicationCode = 'APP202304140001';
            // const applicationCode = 'APP201904250002';
            commit('updateCurrentApplicationCode', applicationCode);
            // 接入配置中心
            // const SwitchStatus = false;
            // if (SwitchStatus) {
            //     applicationCode && fetchSystemSwitch(applicationCode).then(res => {
            //         if (res.code === '0') {
            //             commit('getSystemSwitchs', res.data);
            //         }
            //     });
            // }

            return getAllResources({
                userCode: state.user.userCode,
                applicationCode: state.currentApplicationCode,
                tenantCode: state.currentTenant.tenantCode
                // eslint-disable-next-line complexity
            }).then(resData => {
                if (resData && +resData.code === 0) {
                    const btnPermissions = getResourceIds(resData.data);
                    commit('getBtnPermissions', btnPermissions);
                    commit('updateAuthority', resData.data[0] && resData.data[0].subResources && resData.data[0].subResources.length ? resData.data[0].subResources : []);
                    if (resData.data && resData.data.length === 0) {
                        !window.location.href.includes('/mipIframe?') && Message.error({
                            message: '您没有菜单权限',
                            showClose: true
                        });
                        return false;
                    }
                    const userCode = state.user.userCode.toLocaleLowerCase();
                    if (userCode.indexOf('hc') === 0 || userCode.indexOf('hp') === 0) {
                        return querySupplierInFoBySupplierCode(state.user.userCode).then(resSupplier => {
                            if (resSupplier && +resSupplier.code === 0) {
                                commit('updateSupplier', resSupplier.data);
                            }
                            return resSupplier.data;
                        });
                    }
                    return true;
                } else {
                    Message.error(resData.msg);
                    return false;
                }
            });
        },
        UPDATE_LANG ({ commit, state }) {
            const langSettings = lang.initLanguage(state.locale);
            commit('updateLang', langSettings);
            return langSettings;
        },
        ...tenant.actions,
        ...Msg.actions,
        ...uploadList.actions,
        ...App.actions
    }
};

<template>
    <el-dropdown @command="handleUserCommand" trigger="click">
        <el-button type="text" class="user-name" :icon="`${user && user.picUrl ? '' : 'iconfont icon-topbar_header_medium'}`">
            <img v-if="user && user.picUrl" :src="user.picUrl" class="dropdown-img" alt="">
            {{user ? user.userName : ''}}
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="userCenter" class="icon iconfont icon-button userInfo">
                <em class="icon iconfont icon-button rightIcon">&#xe718;</em>
                个人中心
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="icon iconfont icon-button userInfo">
                <em class="icon iconfont icon-button rightIcon">&#xe66f;</em>
                退出登录
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import { logout } from '@/utils/index';
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['user', 'currentTenant'])
    },
    props: {
        targetRoute: {
            type: String,
            default: '/user-center'
        }
    },
    methods: {
        handleUserCommand(command) {
            if (command === 'logout') {
                logout(true); // true为手动触发登出，false或不传为自动登出，比如401情况强制退出
            }
            if (command === 'userCenter' && this.currentTenant) {
                this.$router.push(this.targetRoute);
            }
        }
    }
};
</script>
<style lang="less">
.dropdown-img{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-bottom: -3px;
    margin-right: 5px;
}
</style>

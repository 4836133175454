import request from '@/utils/http';
const apiPath = '/api-auth';

export default {
    getLang(params) {
        return request({
            url: `${apiPath}/langApplication/translates`,
            method: 'get',
            params: params,
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(res => {
            return {};
        });
    }
};

import {
    getSelectMessage,
    getNotices
} from '@/api/userApi';

export default {
    state: {
        msgList: {
            warning: {
                list: [], // 预警列表
                listPage: 0
            },
            notice: {
                list: [], // 信息列表
                listPage: 0
            }
        }
    },
    getters: {
        msgList: state => state.msgList
    },
    mutations: {
        updateWarningList(state, data) {
            state.msgList.warning = data;
        },
        updateNoticeList(state, data) {
            state.msgList.notice = data;
        }
    },
    actions: {
        /**
         * 获取预警信息列表
         */
        getWarningList({ dispatch, commit, state }, { startlt, endgt, flag, pageSize }) {
            if (flag) {
                commit('updateWarningList', {
                    list: [], // 预警列表
                    listPage: 0
                });
            }
            return getSelectMessage({
                startlt,
                endgt,
                pageNo: state.msgList.warning.listPage + 1,
                pageSize
            }).then(res => {
                if (res && res.data && res.code === '0') {
                    commit('updateWarningList', {
                        ...state.msgList.warning,
                        list: state.msgList.warning.list.concat(res.data.list)
                    });
                    // this.warningList = this.warningList.concat(res.data.list);
                    // this.warningListTotal = res.data.totalCount;
                    if (res.data.list.length < pageSize) {
                        return true;
                        // this.warningHasMore = true;
                        // this.warningLoading = false;
                    }
                    return false;
                }
            });
        },
        /**
         * 获取公告信息列表
         */
        getNoticeList({ dispatch, commit, state }, { flag, startlt, endgt, pageSize }) {
            if (flag) {
                commit('updateNoticeList', {
                    list: [], // 预警列表
                    listPage: 0
                });
            }
            return getNotices({
                startlt,
                endgt,
                noticeStatus: 1, // 0草稿,1发布
                pageNo: state.msgList.notice.listPage + 1,
                pageSize,
                applicationCode: state.currentApplicationCode
            }).then(res => {
                if (res && res.data && res.code === '0') {
                    // this.noticeList = this.noticeList.concat(res.data.list);
                    commit('updateNoticeList', {
                        ...state.msgList.notice,
                        list: state.msgList.notice.list.concat(res.data.list)
                    });
                    // this.noticeListTotal = res.data.totalCount;
                    if (res.data.list.length < pageSize) {
                        return true;
                    }
                    return false;
                }
            });
        }
    }
};

const config = {};

if (process.env.VUE_APP_ENV) {
    config.env = process.env.VUE_APP_ENV;
    config.MIDEA_AUTH = process.env.VUE_APP_MIDEA_AUTH;
    config.singlePointLogin = process.env.VUE_APP_LOGIN_URL;
    config.singlePointLogout = process.env.VUE_APP_LOGOUT_URL;
    config.singlePointURLParamsDomain = process.env.VUE_APP_URL_PARAMS_DOMAIN;
    config.staffSelector = {
        url: process.env.VUE_APP_STAFF_SELECTOR_URL,
        project: process.env.VUE_APP_STAFF_SELECTOR_PROJECT
    };
    config.appId = process.env.VUE_APP_APPID;
    config.defaultPage = process.env.VUE_APP_DEFAULT_PAGE;
    config.uploadAppId = process.env.VUE_APP_UPLOAD_APP_ID;
    config.uploadAppKey = process.env.VUE_APP_UPLOAD_APP_KEY;
    config.apiUrl = process.env.VUE_APP_API_URL;
    config.apiPath = '';
    config.filePreviewUrl = process.env.VUE_APP_File_Preview;
    config.commonBucket = process.env.VUE_APP_COMMON_BUCKET;
    config.baiduMapAK = process.env.VUE_APP_BAIDU_MAP_AK;
    config.bigDataLogTokenUrl = process.env.VUE_APP_BIG_DATA_LOG_TOKEN_URL;
    config.bigDataLogUrl = process.env.VUE_APP_BIG_DATA_LOG_URL;
    config.bigDataLogAppSecrect = process.env.VUE_APP_BIG_DATA_LOG_APP_SECRECT;
    config.bigDataLogAppName = process.env.VUE_APP_BIG_DATA_LOG_APP_NAME;
    config.commentUrl = process.env.VUE_APP_COMMENT_URL;
    config.iflowUrl = process.env.VUE_APP_IFLOW_URL;
    config.mSign = process.env.VUE_APP_MIDEA_SIGN;
    // 前 端监控平台
    config.appKey = process.env.VUE_APP_MONITOR_APP_KEY;
    config.serviceName = process.env.VUE_APP_MONITOR_SERVICE_NAME;
    config.dsn = process.env.VUE_APP_MONITOR_DSN;
} else {
    config.env = 'uat';
    config.MIDEA_AUTH = 'midea_auth_sit';
    config.singlePointLogin = 'https://signinuat.midea.com/?service=';
    config.singlePointLogout = 'http://signinuat.midea.com/logout?service=';
    config.singlePointURLParamsDomain = 'midea.com';
    config.staffSelector = {
        url: 'http://devcloud.midea.com/builder/m_staff_selector-V1.0.1/index.js',
        project: '/mframework'
    };
    config.appId = 'MFRAMEWORK_CLI3.0';
    config.defaultPage = '/index.html';
    config.uploadAppId = '32883248FE8946CCA59DDB36C80BF571';
    config.uploadAppKey = 'Vgjmcgy24Hjwb6Buq7ZfRz0uX2NebGJFrfYDRRPZ';
    config.apiUrl = 'https://anapi-uat.annto.com/';
    config.apiPath = '/api';
    config.filePreviewUrl = 'http://imipsit.midea.com/';
    config.commonBucket = 'annto-lcrm';
    config.baiduMapAK = '8vcZYShWuCGFyiBbHl2aGGSvFeSVZU9k';
    config.bigDataLogTokenUrl = 'https://apisit.midea.com/bigds/token/bigdatatoken';
    config.bigDataLogUrl = 'https://apisit.midea.com/bigds/maidian';
    config.bigDataLogAppSecrect = 'mierj-aklk1j-nh9o1-12ju-aj1ai1vsd';
    config.bigDataLogAppName = 'C-LCRM';
    config.commentUrl = 'http://analysis-sit.annto.com';
    // config.iflowUrl = 'https://iflowsit.midea.com/mse/extFrame.html';
    // config.iflowUrl = 'https://dc-sit.annto.com/iflow-page';
    config.iflowUrl = 'https://dc-uat.annto.com/iflow-page';
    config.mSign = 'https://msigntest.midea.com';
    config.appKey = '8d7755336a6d45a19702528bde717b36';
    config.serviceName = 'c-lcrm-web';
    config.dsn = 'https://mwmr.midea.com/xops/web-endpoint/report';
}

export default config;

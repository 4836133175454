/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import bigDataLog from '@/utils/bigDataLog';
import bigDataConfig from '@/bigData/index';
import Home from '@/Home.vue';
import Root from '@/Root.vue'

import {
    splitModName,
    getModResources,
    generateRoutes

} from '../utils/module';
import config from '@/config/user.env';
import cookies from 'js-cookie';
import { logout } from '../utils/index';
Vue.use(Router);

export const routeConfig = [
    {
        path: window.__MICRO_APP_BASE_ROUTE__ || '/',
        component: Home,
        redirect: '/index',
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/modules/redirect/index')
            },
            {
                path: '/index',
                name: 'index',
                component: () => import('@/views/index/Main.vue')
            },
            {
                path: '/user-center',
                name: 'userCenter',
                component: () => import('@/components/lots/userCenter/UserCenter.vue')
            },
            {
                path: '/mipIframe',
                name: 'mipIframe',
                meta: { title: '合同流程审批' },
                component: () => import('@/modules/mdm/views/contractModules/components/mipIframe.vue')
            },
            {
                path: '/opportunityApplication',
                name: 'opportunityApplication',
                meta: { title: '商机准入申请' },
                component: () => import('@/modules/mdm/views/opportunityModules/opportunityApplication/Index.vue')
            },
            {
                path: '/contractRegistrationToDo',
                name: 'contractRegistrationToDo',
                meta: { title: '合同续签待办' },
                component: () => import('@/modules/mdm/views/contractModules/contractRegistrationToDo/Index.vue')
            },
            {
                path: '/strategicCooperationMaintenance',
                name: 'strategicCooperationMaintenance',
                meta: { title: '战合客户信息快速维护' },
                component: () => import('@/modules/mdm/views/customerModules/strategicCooperationMaintenance/Index.vue')
            },
            {
                path: '/strategicCooperationIntroduceApply',
                name: 'strategicCooperationIntroduceApply',
                meta: { title: '战合客户引入申请' },
                component: () => import('@/modules/mdm/views/customerModules/strategicCooperationIntroduceApply/Index.vue')
            },
            {
                path: '/strategicCooperationIntroduceUpdate',
                name: 'strategicCooperationIntroduceUpdate',
                meta: { title: '战合客户信息修改' },
                component: () => import('@/modules/mdm/views/customerModules/strategicCooperationIntroduceUpdate/Index.vue')
            },
            {
                path: '/customerPersonResponserEdit',
                name: 'customerPersonResponserEdit',
                meta: { title: '责任人变更流程' },
                component: () => import('@/modules/mdm/views/customerModules/customerPersonResponserEdit/Index.vue')
            },
            {
                path: '/strategicCooperationTermination',
                name: 'strategicCooperationTermination',
                meta: { title: '战合客户终止申请' },
                component: () => import('@/modules/mdm/views/customerModules/strategicCooperationTermination/Index.vue')
            },
            {
                path: '/approveFlow',
                name: 'approveFlow',
                meta: { title: '流程信息' },
                component: () => import('@/modules/mdm/views/contractModules/approveFlow/Index')
            },
            {
                path: '/resourceApplication',
                name: 'resourceApplication',
                meta: { title: 'IT资源对接申请' },
                component: () => import('@/modules/mdm/views/opportunityModules/resourceApplication/Index.vue')
            },
            {
                path: '/opportunityBatch',
                name: 'opportunityBatch',
                meta: { title: '商机批量创建' },
                component: () => import('@/modules/mdm/views/opportunityModules/opportunityBatch/Index.vue')
            },
        ]
    },
    {
        path: '/mipIframe',
        name: 'mipIframe',
        meta: { title: '合同流程审批' },
        component: () => import('@/modules/mdm/views/contractModules/components/mipIframe.vue')
    },
    {
        path: '/opportunityApplication',
        name: 'opportunityApplication',
        meta: { title: '商机准入申请' },
        component: () => import('@/modules/mdm/views/opportunityModules/opportunityApplication/Index.vue')
    },
    {
        path: '/contractRegistrationToDo',
        name: 'contractRegistrationToDo',
        meta: { title: '合同续签待办' },
        component: () => import('@/modules/mdm/views/contractModules/contractRegistrationToDo/Index.vue')
    },
    {
        path: '/strategicCooperationMaintenance',
        name: 'strategicCooperationMaintenance',
        meta: { title: '战合客户信息快速维护' },
        component: () => import('@/modules/mdm/views/customerModules/strategicCooperationMaintenance/Index.vue')
    },
    {
        path: '/strategicCooperationIntroduceApply',
        name: 'strategicCooperationIntroduceApply',
        meta: { title: '战合客户引入申请' },
        component: () => import('@/modules/mdm/views/customerModules/strategicCooperationIntroduceApply/Index.vue')
    },
    {
        path: '/customerPersonResponserEdit',
        name: 'customerPersonResponserEdit',
        meta: { title: '责任人变更流程' },
        component: () => import('@/modules/mdm/views/customerModules/customerPersonResponserEdit/Index.vue')
    },

];

if (window.__MICRO_APP_ENVIRONMENT__) {
    routeConfig[0].component = Root;
    routeConfig[0].children.forEach(item => {
        item.path = window.__MICRO_APP_BASE_ROUTE__ + item.path;
    });
}

const router = new Router({
    // base: process.env.BASE_URL,
    routes: routeConfig
});

// 记录注册过的模块
const registeredRouterRecord = [];

/**
 * @description 检查模块是否注册
 * @param {String} modName 模块名
 */
const isModRegistered = modName => {
    return registeredRouterRecord.includes(modName);
};

/**
 * @description 注册模块
 * @param {String} modName 模块名
 */
const regeisterMod = (modName, cb) => {
    getModResources(modName).then(res => {
        // generate routes
        generateRoutes(modName, res.router).then(appendRoutes => {
            // register router
            appendRoutes.forEach(item => {
                if (!_.startsWith(item.path, 'http') && item.component) {
                    router.addRoute({
                        path: '/',
                        component: window.__MICRO_APP_ENVIRONMENT__ ? Root : Home,
                        children: [
                            {
                                path: item.path,
                                name: item.name,
                                component: item.component,
                                meta: {
                                    resourceName: item.meta.resourceName
                                }
                            }
                        ]
                    });
                }
            });
            cb();
        });

        // register store
        store.registerModule(modName, res.store);

        registeredRouterRecord.push(modName);
    });
};

router.beforeEach((to, from, next) => {
    const modName = splitModName(to.path);
    // 非基础模块 + 模块未注册 = 需要注册模块
    if (modName && !isModRegistered(modName)) {
        regeisterMod(modName, next.bind(null, to.fullPath));
        return;
    }
    if (to.meta.resourceName) {
        // 各页面添加各自大数据埋点全局变量
        window.bigDataConfig = bigDataConfig[to.name] || {};
        bigDataLog.sendMainMessage();
    }
    next();
});
export default router;

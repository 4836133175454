<template>
    <el-dropdown @command="handlerSelete" class="lang-seletor">
        <el-button type="text" class="icon iconfont icon-button icon-fixed-size">&#xe6bc;</el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="legal">隐私声明</el-dropdown-item>
            <el-dropdown-item command="about">联系我们</el-dropdown-item>
            <el-dropdown-item command="terms">服务条款</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    methods: {
        handlerSelete(command) {
            if (command === 'legal') {
                window.open('https://static.annto.com/res/webInfo/privacy.html');
            } else if (command === 'about') {
                window.open('https://static.annto.com/res/webInfo/contact_us.html');
            } else if (command === 'terms') {
                window.open('https://static.annto.com/res/webInfo/service.html');
            }
        }
    }
};
</script>

<style>

</style>

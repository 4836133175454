import {
    getUserFileList
} from '@/api/userApi';

export default {
    state: {
        fileList: {
            loading: false, // 表格加载中显示
            tableData: [], // 实际数据列表
            columns: [ // 表格配置项
                {
                    label: '文件名',
                    prop: 'localFileName',
                    width: '160'
                },
                {
                    label: '状态',
                    prop: 'fileStatus',
                    width: '100'
                },
                {
                    label: '更新时间',
                    prop: 'updateTime',
                    width: '150'
                }
            ],
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页条数
            loadMoreStatus: false, // 加载更多的状态
            loadMoreFinished: false, // 加载完成状态，true为已全部加载完
            loadMoreText: '' // 下拉加载显示的文字
        }
    },
    getters: {
        fileList: state => state.fileList
    },
    mutations: {
        updateFileList(state, fileList) {
            state.fileList = fileList;
        }
    },
    actions: {
        /**
         * 获取文件列表
         */
        /* eslint-disable max-lines-per-function */
        getFileList({ dispatch, commit, state }, { val }) {
            commit('updateFileList', {
                ...state.fileList,
                loadMoreStatus: true
            });
            if (val && val === 'add') {
                commit('updateFileList', {
                    ...state.fileList,
                    currentPage: state.fileList.currentPage + 1
                });
            } else {
                commit('updateFileList', {
                    ...state.fileList,
                    ...{
                        loading: true,
                        loadMoreFinished: false,
                        currentPage: 1,
                        tableData: [],
                        loadMoreText: ''
                    }
                });
            }
            const params = {
                asc: 'desc',
                orderBy: 'updateTime',
                pageSize: state.fileList.pageSize,
                pageNo: state.fileList.currentPage
            };
            getUserFileList(params).then(res => {
                if (res && res.code === '0') {
                    commit('updateFileList', {
                        ...state.fileList,
                        loading: false,
                        loadMoreStatus: false
                    });
                    const list = res.data.list;
                    if (state.fileList.pageSize >= list.length) {
                        commit('updateFileList', {
                            ...state.fileList,
                            tableData: state.fileList.tableData.concat(list)
                        });
                    }
                    if (state.fileList.pageSize > list.length) {
                        commit('updateFileList', {
                            ...state.fileList,
                            loadMoreFinished: true,
                            loadMoreText: '已经加载到底了！'
                        });
                    } else {
                        commit('updateFileList', {
                            ...state.fileList,
                            loadMoreFinished: false
                        });
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};

import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import permission from './modules/permission';
import tagsView from './modules/tagsView';
import getters from './getter';

Vue.use(Vuex);

const store = window.store = window.store || new Vuex.Store({
    modules: {
        user,
        permission,
        tagsView
    },
    getters
});
export default store;

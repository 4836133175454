import http from '@/utils/http';
import config from '@/config/user.env';

const findCurrUserUrl = '/api-auth/userInfo/getLogIn';
const getResuorceBySysAuthUrl = `/mframework/sys/authority/sysAuthResource/getResuorceBySysAuth?appId=${config.appId}`;
const switchLangUrl = '/mframework/sys/org/savePersonConf';
const getAllTenantsUrl = '/api-auth/tenant/getUserAllTenants';
const getAllResourcesUrl = '/api-auth/getAllResources';
const getApplication = '/api-auth/getUserApplications';
const logoutAPI = '/api-auth/ssoLogout';

/**
 * 登录后查找当前用户信息
 */
export function findCurrUser (params) {
    return http({
        url: findCurrUserUrl,
        method: 'get',
        params: params
    });
}
// 获取应用开关配置
export function fetchSystemSwitch (applicationCode) {
    return http({
        url: '/api-lcp-dop/gov/resource/config2/list?applicationCode=' + applicationCode,
        method: 'get'
    });
}

/**
 * 登录后获取当前租户信息
 */
export function getUserAllTenants (params) {
    return http({
        url: getAllTenantsUrl,
        method: 'get',
        params: params
    });
}

/**
 * 退出登录
 */

export function LogoutApi (params) {
    return new Promise((resolve, reject) => {
        http({
            url: logoutAPI,
            method: 'get',
            params: params
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        });
    });
}

/**
 * 设置租户
 */
export function setTenant (data) {
    return http({
        url: `/api-auth/setTenant/${data.tenantCode}`,
        method: 'put'
    });
}

/**
 * 获取应用
 */
export function getUserApplications (params) {
    return http({
        url: getApplication,
        method: 'get',
        params: params
    });
}

// 获取菜单
export const getAllResources = (params) => {
    return http({
        url: getAllResourcesUrl,
        method: 'get',
        params: params
    });
};

/**
 * 获取菜单
 */
export function getResuorceBySysAuth () {
    return http({
        url: getResuorceBySysAuthUrl,
        method: 'get'
    });
}

/**
 * 语言切换
 */
export function switchLang (langType) {
    // debugger;
    return http({
        url: switchLangUrl,
        method: 'post',
        data: {
            langType
        }
    });
}

/**
 * 获取承运商
 */
export const querySupplierInFoBySupplierCode = (params) => {
    return http({
        url: `/api-srm-web/supplier/querySupplierInfoByUserCode/${params}`,
        method: 'get'
    });
};

// ------消息中心
// 查询当前用户消息
export const getSelectMessage = (params) => {
    return http({
        url: `/api-msg/selectMessage`,
        method: 'get',
        params: params
    }).then(res => res);
};

// 获取系统公告
export const getNotices = (params) => {
    return http({
        url: `/api-msg/notices`,
        method: 'get',
        params: params
    }).then(res => res);
};

/**
 * 获取供应商证件预警信息
 */
export const getSupplierQualifications = (param) => {
    return http({
        url: `/api-srm-web/supplier/strongControlWarnings/getWarningInfo`,
        method: 'get',
        params: {
            tenantCode: param
        }
    });
};
/**
 * 获取文件列表
 */
export const getUserFileList = (params) => {
    return http({
        url: `/api-oss/oss/file/info/page`,
        method: 'get',
        params: params
    });
};
/**
 * 获取我要建议外网token
 */
export const getItsmToken = (params) => {
    return http({
        url: `/api-lcrm/getOutToken`,
        method: 'get',
        params: params
    });
};

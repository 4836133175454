<template>
    <div class="side-bar" ref="aside" :class="{'menu-collapsed': !isCollapse}">
        <h1 class="side-bar-title">
            <lots-icon name="LC-logo" class="logo-image" alt="logisitc cloud" />
            <lots-icon name="LC-logo-text" class="logo-text" v-if="!isCollapse"  alt="logisitc cloud" />
        </h1>
        <el-scrollbar class="side-bar-scrollbar">
            <sidebar-menus :menus="searchMenus" :collapse="isCollapse"></sidebar-menus>
        </el-scrollbar>
        <div class="side-bar-btn-wrap">
            <el-input prefix-icon="iconfont iconsearch_Inquire"
                      :placeholder="$translate('component.E202007300009', { defaultText: '请输入内容' })"
                      class="side-bar-search"
                      size="mini"
                      v-show="!isCollapse"
                      v-model="searchMenuName"
                      @keyup.enter.native="handleEnter"></el-input>
            <el-button class="side-bar-open-btn" type="text" :icon="isCollapse ? 'iconfont icon-unfold': 'iconfont icon-retract'" @click="togglePay"></el-button>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import sidebarMenus from './CoreSidebarMenus';
import { I18nMixins } from '@/components/mixins/i18n';
import lotsIcon from '../lots/lotsIcon';
import bigDataLog from '@/utils/bigDataLog.js';
import utils from '../utils/common.js';
export default {
    mixins: [I18nMixins],
    components: {
        sidebarMenus,
        lotsIcon
    },
    data() {
        return {
            originMenu: [],
            searchMenus: [],
            searchMenuName: null,
            isCollapse: false
        };
    },
    computed: {
        menus() {
            return this.authority.length ? this.authority.map(item => {
                item['fdFrontLink'] = item.frontUrl;
                item['path'] = item.frontUrl;
                item['name'] = item.resourceName;
                return item;
            }) : [];
        },
        ...mapGetters(['authority', 'user', 'appList', 'currentApplicationCode'])
    },
    methods: {
        togglePay: function() {
            this.isCollapse = !this.isCollapse;
            this.$eventBus.$emit('sidebarIsCollapse', this.isCollapse);
        },
        getResourceIds(v) {
            const tempArr = [];
            getId(v);

            function getId(v) {
                v.forEach(item => {
                    if (item.resourceType && +item.resourceType === 1 && item.subResources) {
                        getId(item.subResources);
                    } else if (item.resourceType && +item.resourceType === 2) {
                        tempArr.push(item);
                    }
                });
            }
            return tempArr;
        },
        handleEnter() {
            if (this.searchMenuName) {
                let temp = this.getResourceIds(this.originMenu);
                temp = temp.filter(item => {
                    return item.resourceName.indexOf(this.searchMenuName) !== -1;
                });
                this.searchMenus = temp || [];
            } else {
                this.searchMenus = this.originMenu || [];
            }
            if (window._paq) {
                window._paq.push(['trackEvent', 'Menu', 'Enter', '菜单搜索']);
            }
        },
        menuInfoToMap(menuInfo) {
            const infoMap = new Map();
            menuInfo.forEach((item, index) => {
                if (item) {
                    const mapIndex = index + 1;
                    infoMap.set('model_name' + mapIndex, item);
                }
            });
            return infoMap;
        },
        mapToMessageObj(map) {
            const obj = Object.create(null);
            for (const [key, value] of map) {
                obj[key] = value;
            }
            return obj;
        },
        getCurrenMenuInfo(element) {
            const info = [];
            let frontUrl = ''; // 当前菜单实际的url
            let resourceCode = ''; // 当前菜单resourceCode
            let isFirstTime = true;
            const getNodeInfo = (el) => {
                let menuItem;
                if (isFirstTime) {
                    isFirstTime = false;
                    menuItem = el.closest('.el-menu-item');
                    if (menuItem) {
                        // 如果子菜单项，则向上查询, 有子菜单的主菜单项则不上报
                        getNodeInfo(menuItem);
                        frontUrl = menuItem.attributes['frontUrl'] && menuItem.attributes['frontUrl'].nodeValue;
                        resourceCode = menuItem.attributes['resourceCode'] && menuItem.attributes['resourceCode'].nodeValue;
                        info.push(menuItem.innerText);
                    }
                } else {
                    const parentMenu = el.parentNode.closest('.el-submenu');
                    if (parentMenu) {
                        menuItem = parentMenu.querySelector('.el-submenu__title');
                        getNodeInfo(parentMenu);
                        info.push(menuItem.innerText);
                    }
                }
            };
            getNodeInfo(element);
            if (!frontUrl.startsWith('http')) {
                const { origin, pathname } = window.location;
                frontUrl = origin + pathname + '#' + frontUrl;
            }
            return { info, frontUrl, resourceCode };
        },
        sendLog(element) {
            const { info, frontUrl, resourceCode } = this.getCurrenMenuInfo(element);
            if (info && info.length) {
                utils.setLocalStorage(info, frontUrl, resourceCode);
                const infoMap = this.menuInfoToMap(info);
                const message = this.mapToMessageObj(infoMap);
                bigDataLog.sendDetailMessage(message);
            }
        },
        ...mapMutations({
            updateAuthority: 'updateAuthority'
        })
    },
    created() {
        // 发送常用菜单请求
        utils.sendMenuData();
        const requireContext = require.context('../../assets/', false, /\.svg$/);
        requireContext.keys().forEach(requireContext);
        this.searchMenus = JSON.parse(JSON.stringify(this.authority)) || [];
        const { clientWidth } = document.body;
        const minWidth = 1366;
        if (clientWidth <= minWidth) { // 文档宽度小于1366时，默认收起菜单
            this.isCollapse = true;
        }
    },
    watch: {
        authority: {
            handler(newValue) {
                this.originMenu = JSON.parse(JSON.stringify(newValue));
                this.searchMenus = JSON.parse(JSON.stringify(this.originMenu)) || [];
            },
            immediate: true
        }
    },
    mounted() {
        // 添加菜单访问统计
        this.$nextTick(() => {
            const { aside } = this.$refs;
            aside.addEventListener('click', (e) => {
                let element = e.target;
                this.sendLog(element);
                if (window._paq) {
                    if (element.classList.contains('icon-')) {
                        element = element.parentNode;
                    }
                    if (element.classList.contains('el-menu-item') || element.parentNode.classList.contains('el-menu-item')) {
                        window._paq.push(['trackEvent', 'Menu', 'Click', element.innerText]);
                    }
                }
            });
        });
        this.$eventBus.$emit('sidebarIsCollapse', this.isCollapse);
    }
};
</script>
<style lang="less">
.menu-collapsed {
    width: 200px;
}
.side-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    .side-bar-title {
        flex: 0 0 50px;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        height: 50px;
        margin: 0;
        padding: 0 0 0 15px;
    }
    .side-bar-scrollbar{
        flex: 1;
        .el-scrollbar__thumb{
            background-color: rgba(255,255,255,.1)
        }
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .logo-image {
        display: inline-block;
        width: 20px;
        height: 20px;
        overflow: hidden;
        vertical-align: middle;
    }
    .logo-text {
        width: 120px!important;
        height: 25px!important;
        vertical-align: middle!important;
    }
    .el-menu-item,
    .el-submenu__title {
        height: 40px;
        line-height: 40px;
    }
    .el-submenu [class^=el-icon-],
    .el-menu-item [class^=el-icon-],
    .el-menu--collapse>.el-menu-item [class^=el-icon-],
    .el-menu--collapse>.el-submenu>.el-submenu__title [class^=el-icon-] {
        margin-left: -8px;
    }
    // .is-active {
    //     background: #4285F5 !important;
    // }
    .side-bar-btn-wrap {
        background-color: #0f1012;
        flex: 0 0 40px;
    }
    .side-bar-search {
        width: 130px;
        height: 24px;
        margin: 0 10px;
        .el-input__inner {
            border: 0;
            background: #33353A;
            padding-right: 10px;
            padding-left: 25px;
        }
        .el-input__prefix {
            left: 6px;
            color: #fff;
        }
    }
    .side-bar-open-btn {
        height: 40px;
        width: 50px;
        border-left: 1px solid #2E2F36;
        color: #fff;
    }
}
.el-menu--popup-right-start {
    margin-left: 0;
    margin-right: 0;
    margin-top: -5px;
    padding: 0;
    .el-menu-item,
    .el-submenu__title {
        font-size: 12px;
        height: 40px;
        line-height: 40px;
    }
    .el-menu--popup-right-start {
        margin-top: 0;
    }
}
</style>

// 客户账号管理-菜单 L3
const config = {
    'accountManage': { // 客户账号管理-页面 L4
        'bigData-btn-viewPhone': '客户账号管理,客户账号管理-账号列表,,查看手机', // 查看手机-按钮 L6
        'bigData-btn-viewEmail': '客户账号管理,客户账号管理-账号列表,,查看邮箱', // 查看邮箱-按钮 L6
        'bigData-btn-viewAccount': '客户账号管理,客户账号管理-账号列表,,查看账号' // 查看账号-按钮 L6
    }
};

export default config;

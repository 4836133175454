<template>
    <div ref="scrollPane" class="tags-view-wrapper" id="tags-view-container">
        <div class="all-tags-inner" v-if="isShowTabs">
            <router-link v-for="tag in visitedViews.slice(0, 5)" ref="tag" :class="isActive(tag)?'active':''"
                         :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }" :key="tag.fullPath" tag="span"
                         class="tags-view-item-all" @click.middle.native="closeSelectedTag(tag)"
                         @contextmenu.prevent.native="openMenu(tag,$event)">
                   <span class="tags-view-item">{{getTranslate(tag)}}{{tag.orderNo}}
                    <span class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" /></span>
            </router-link>
            <el-dropdown trigger="click" class="more-dropdown" v-if="visitedViews.length > 5">
                <span class="el-dropdown-link">
                   <em class="icon iconfont">&#xe66b;</em>
                    <!-- $t('components.tagsNav.allTags') -->
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, index) in allTags" :key="index" @click.native="handleCloseAll(item, index)" class="tag-item">
                        {{getTranslate(item)}}<span class="el-icon-close" @click.prevent.stop="closeSelectedTag(item)"></span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <ul v-show="visible" :style="{left:left+'px'}" class="contextmenu">
            <li @click="refreshSelectedTag(selectedTag)">{{ $translate('component.E202006152491',{ defaultText: '刷新' }) }}</li>
            <li @click="closeSelectedTag(selectedTag)">{{ $translate('component.E202007300005',{ defaultText: '关闭' }) }}</li>
            <li @click="closeOthersTags">{{ $translate('component.E202007300006',{ defaultText: '关闭其他' }) }}</li>
            <li @click="closeAllTags">{{ $translate('component.E202007300007',{ defaultText: '全部关闭' }) }}</li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import { I18nMixins } from '@/components/mixins/i18n';
import Utils from '@/components/utils/common.js';
Vue.prototype.$eventBus = new Vue();
const SHOW_MENU_LENGTH = 5;
export default {
    mixins: [I18nMixins],
    props: {
        isShowLang: {
            type: Boolean,
            default: true
        },
        isShowTenant: {
            type: Boolean,
            default: true
        },
        isShowTabs: {
            type: Boolean,
            default: true
        },
        hasHeaderOrg: {
            type: Boolean,
            default: false
        },
        isShowTool: {
            type: Boolean,
            default: true
        },
        hasUploadList: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: false,
            left: 0,
            selectedTag: {},
            closeAllTag: [{ resourceName: '关闭所有', id: 'closeAll', meta: { title: '关闭所有' } }],
            allTags: [],
            warningList: [], // 预警列表
            warningType: ['', '消息', '预警'], // 预警类型
            noticeList: [], // 信息列表
            noticeType: ['', '公告', '预警', '维护', '更新'], // 信息类型
            msgDialogData: {}, // 信息弹窗数据
            showMessageTips: true, // 是否显示右上角消息下拉框
            warningListPage: 0,
            noticeListPage: 0,
            listPageSize: 10,
            warningListTotal: 0,
            noticeListTotal: 0,
            warningHasMore: true,
            noticeHasMore: true,
            warningScrollDisabled: true,
            noticeScrollDisabled: true,
            warningLoading: false,
            noticeLoading: false,
            flatResourceSearch: [] // 扁平化的菜单信息
        };
    },
    computed: {
        ...mapGetters(['user', 'visitedViews', 'cachedViews', 'tenantInfo', 'authority']),
        tagNameFormat: () => name => {
            return _.startsWith(name, 'components.') ? name : `components.${name}`;
        }
    },
    watch: {
        authority(val) {
            if (!val.length) { // 没有菜单权限tab栏关闭所有页面去到404页面
                this.closeAllTags();
            }
        },
        $route() {
            this.addViewTags();
            this.moveToCurrentTag();
        },
        visible(value) {
            if (value) {
                document.body.addEventListener('click', this.closeMenu);
            } else {
                document.body.removeEventListener('click', this.closeMenu);
            }
        }
    },
    mounted() {
        this.$eventBus.$on('closeTag', param => {
            const delay = 50;
            setTimeout(() => {
                this.closeSelectedTag(param);
            }, delay);
        });
        this.addViewTags();
        const dbody = document.getElementById('tags-view-container');
        this.mousewheelTags(dbody);
        this.$eventBus.$on('languageChange', (langCode) => {
            const activeItem = this.visitedViews.find(item => {
                return this.isActive(item);
            });
                // 主动重新加载
            if (activeItem) {
                this.refreshSelectedTag(activeItem);
            }
            // 清除现有tab所有缓存
            for (let i = 0; i < this.visitedViews.length; i++) {
                this.removeKeepAlivePage(this.visitedViews[i].name.split('-')[1]);
            }
        });
    },
    created() {
        const menu = this.authority;

        this.getFlatResource(menu);
    },
    methods: {
        getTranslate(tag) {
            const { resourceCode, resourceName } = tag;
            if (resourceCode && resourceName) {
                return this.$translate(`lang.${tag.resourceCode}`, { defaultText: tag.resourceName });
            } else {
                return (tag.meta && tag.meta.title) || tag.name;
            }
        },
        isActive(route) {
            return route.fullPath.split('?')[0] === this.$route.fullPath.split('?')[0];
        },
        getFlatResource(resource, deep = 0) {
            for (let i = 0; i < resource.length; i++) {
                const ignoreType = 3;
                if (resource[i].resourceType !== ignoreType) {
                    this.flatResourceSearch.push(resource[i]);
                }

                if (resource[i].subResources) {
                    if (resource[i].subResources.length >= 0) {
                        const nextDeep = deep + 1;
                        this.getFlatResource(resource[i].subResources, nextDeep);
                    }
                }
            }
        },
        addViewTags() {
            this.$emit('open-keep-alive');
            const { name } = this.$route;
            // 根据路径找到菜单信息
            let targetMenu = {};
            const findTargetMenu = this.flatResourceSearch.find((item) => {
                return item.frontUrl === this.$route.path;
            });
            targetMenu = findTargetMenu || {};
            if (this.$route.fullPath === '/' || this.$route.fullPath === '/index') {
                targetMenu.resourceCode = 'reIndex';
                targetMenu.resourceName = '首页';
            }
            // 这里就是'/'
            if (name === 'm') {
                this.$router.push('/index');
                return true;
            }
            const temp = this.visitedViews.some(item => {
                return item.name === name;
            });
            if (name) {
                this.$store.dispatch('addView', { ...targetMenu, ...this.$route });
                if (this.visitedViews.length > SHOW_MENU_LENGTH && !temp) {
                    let tempArr = [];
                    tempArr = Utils.ArrMove(this.visitedViews, this.visitedViews.length - 1, SHOW_MENU_LENGTH);
                    tempArr = Utils.ArrMove(tempArr, 0, tempArr.length - 1);
                    this.COVER_VISITED_VIEW(tempArr);
                    this.allTags = this.closeAllTag.concat(this.visitedViews.slice(SHOW_MENU_LENGTH));
                }
            }
            this.allTags = this.closeAllTag.concat(this.$store.getters.visitedViews.slice(SHOW_MENU_LENGTH));
            return false;
        },
        moveToCurrentTag() {
            const tags = this.$refs.tag;
            this.$nextTick(() => {
                if (!tags) {
                    return;
                }
                for (const tag of tags) {
                    if (tag.to.fullPath === this.$route.fullPath) {
                        break;
                    }
                }
            });
        },
        refreshSelectedTag(view) {
            this.removeKeepAlivePage(view.name.split('-')[1]);
            this.$store.dispatch('delCachedView', view).then(() => {
                const { fullPath } = view;
                this.$nextTick(() => {
                    this.$router.replace({
                        path: `/redirect${fullPath}`,
                        query: {
                            routeName: view.name.split('-')[1]
                        }
                    });
                });
            });
        },
        // 控制页面缓存
        handlePageCache(view) {
            const compName = _.get(view, 'matched[0].components.default.name');
            if (compName) {
                this.removeKeepAlivePage(compName);
                setTimeout(() => {
                    this.insertKeepAlivePage(compName);
                }, 1000);
            }
        },
        handleDestroyed(router) {
            const { path, matched } = router;
            let matchedRouter;
            if (matched) {
                matchedRouter = matched.find(item => {
                    return item.path === path;
                });
            }
            if (matchedRouter) {
                const { destroyPage } = matchedRouter.components.default;
                destroyPage && destroyPage.call(matchedRouter.instances.default);
            }
        },
        closeSelectedTag(view) {
            if (view.id && view.id === 'closeAll') {
                this.closeAllTags();
                this.$emit('refresh-keep-alive', 'closeAll');
                return false;
            }
            // this.handleDestroyed(view);
            this.$eventBus.$emit(view.name);
            this.$emit('refresh-keep-alive', view);
            this.$store.dispatch('delView', view).then(({ visitedViews }) => {
                this.handlePageCache(view);
                if (this.isActive(view)) {
                    const latestView = visitedViews.pop();
                    if (latestView) {
                        this.$router.push(latestView);
                    } else {
                        this.$router.push('/index');
                    }
                }
                this.allTags = this.closeAllTag.concat(visitedViews.slice(SHOW_MENU_LENGTH));
            });
        },
        closeOthersTags() {
            // 不在选中的标签页时跳转
            if (this.$route.name !== this.selectedTag.name) {
                this.$router.push(this.selectedTag);
            }
            // 控制页面缓存
            _.filter(this.visitedViews, o => this.selectedTag.name !== o.name).forEach(view => {
                this.handlePageCache(view);
            });
            this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
                this.moveToCurrentTag();
            });
        },
        closeAllTags() {
            _.forEach(this.visitedViews, view => {
                // 控制页面缓存
                this.handlePageCache(view);
            });
            this.$store.dispatch('delAllViews');
            this.$router.push('/index');
        },
        openMenu(tag, e) {
            const menuMinWidth = 105;
            const offsetWidth = this.$el.offsetWidth; // container width
            const maxLeft = offsetWidth - menuMinWidth; // left boundary
            const left = e.clientX; // 15: margin right

            if (left > maxLeft) {
                this.left = maxLeft;
            } else {
                this.left = left;
            }

            this.visible = true;
            this.selectedTag = tag;
        },
        closeMenu() {
            this.visible = false;
        },
        handleCloseAll(item, index) {
            if (item.id && item.id === 'closeAll') {
                this.closeAllTags();
                this.$emit('refresh-keep-alive', 'closeAll');
            } else {
                let tempArr = [];
                tempArr = Utils.ArrMove(this.visitedViews, index + SHOW_MENU_LENGTH - 1, SHOW_MENU_LENGTH);
                tempArr = Utils.ArrMove(tempArr, 0, tempArr.length - 1);
                this.COVER_VISITED_VIEW(tempArr);
                this.allTags = this.closeAllTag.concat(this.visitedViews.slice(SHOW_MENU_LENGTH));
                this.$router.push({ path: item.path, query: item.query, fullPath: item.fullPath });
            }
        },
        mousewheelTags(dbody) {
            // ff用
            this.objAddEvent(dbody, 'DOMMouseScroll', (e) => {
                return this.mouseScroll(e, dbody);
            });
            // 非ff chrome 用
            this.objAddEvent(dbody, 'mousewheel', (e) => {
                return this.mouseScroll(e, dbody);
            });
            // chrome用
            this.objAddEvent(dbody, 'mousewheel', (e) => {
                return this.mouseScroll(e, dbody);
            });
        },
        // 这个是给对象增加监控方法的函数
        objAddEvent(oEle, sEventName, fnHandler) {
            if (oEle.attachEvent) {
                oEle.attachEvent('on' + sEventName, fnHandler);
            } else {
                oEle.addEventListener(sEventName, fnHandler, true);
            }
        },
        mouseScroll(e, dbody) {
            e = e || window.event;
            e.stopPropagation();
            e.preventDefault();
            const STEP = 50;
            const TIMES = 40;
            const delD = e.wheelDelta ? e.wheelDelta : -e.deltaX * TIMES; // 判断上下方向
            const moves = delD > 0 ? -STEP : STEP;
            dbody.scrollLeft += moves; // 非chrome浏览器用这个
            // chrome浏览器用这个
            if (dbody.scrollLeft === 0) {
                dbody.scrollLeft += moves;
            }
            return false;
        },
        ...mapMutations({
            COVER_VISITED_VIEW: 'COVER_VISITED_VIEW'
        })
    }
};

</script>

<style lang="less">
    .tags-view-wrapper {
        flex: 1;
        overflow-x: auto;
        overflow-y: hidden;
        .all-tags-inner {
            height: 100%;
            overflow: visible;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            align-content: center;
        }
        .contextmenu {
            margin: 0;
            background: #1e2028;
            z-index: 100;
            top: 40px;
            position: fixed;
            list-style-type: none;
            line-height: 1.5em;
            font-size: 14px;
            padding: 10px 0;
            border-radius: 4px;
            font-weight: 400;
            color: #fff;

            li {
                margin: 0;
                padding: 9px 40px 9px 20px;
                cursor: pointer;
                &:hover {
                    background: #000;
                }
            }
        }
        .tags-view-item-all {
            cursor: pointer;
            display: inline-block;
            line-height: 40px;
            padding: 0 8px;
            font-size: 14px;
            color:#999;
            &:hover {
                color:#4285F5;
                .el-icon-close {
                    display: block;
                    display: inline;
                }
            }
            &.active {
                color:#4285F5;
                .el-icon-close {
                    display: block;
                    display: inline;
                }
                &:after {
                    content:none;
                }
            }
            .el-icon-close {
                margin-left: 10px;
                color: #999;
            }

        }
        .tags-view-item-all.active {
            .el-icon-close:hover {
                background-color: rgba(255, 255, 255, 0.9);
                color: #00bcbf;
            }
            .tags-view-item {
                border: none;
                top:3px;
            }
        }
        .more-dropdown{
            line-height: 40px;
            padding: 0 8px;
            cursor: pointer;
        }
        .el-icon-close {
            width: 12px;
            height: 12px;
            line-height: 12px;
            border-radius: 50%;
            text-align: center;
            transition: all .3s cubic-bezier(.645, .045, .355, 1);
            transform-origin: 100% 50%;
            display: none;

            &:before {
                transform: scale(.6);
                display: inline-block;
                vertical-align: -2px;
                text-align: center;
                font-size: 18px;
                margin-left: -3px;
            }

            &:hover {
                background-color: #b4bccc;
                color: #fff;
            }
        }

        .tags-view-wrapper {
        }
        .all-tags {
            line-height: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .file-list{
                cursor: pointer;
                line-height: 40px;
                margin-right: 20px;
                font-size: 20px;
            }
        }
    }
    .el-dropdown-menu {
        max-height: 400px;
        overflow: auto;
        background: #1E2028;
        .popper__arrow {
            display:none;
        }
        .el-dropdown-menu__item {
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #ffffff;
            font-size: 14px;
            position: relative;
            padding: 0 20px 0 20px;
            .el-icon-close {
                position: absolute;
                right: 10px;
                top:12px;
            }
            &:hover {
                background: #000;
                color: #fff;
            }
        }
        a:first-child {
            .tag-item {
                color: #ffffff;
                font-weight: bold;
            }
        }
    }
</style>

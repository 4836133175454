import cookies from 'js-cookie';
import { MessageBox } from 'element-ui';
import config from '@/config/user.env';
import { LogoutApi } from '@/api/userApi';
import store from '@/store';
import bigDataLog from '@/utils/bigDataLog';
/**
 * @description 退出登录
 */
export async function logout(flag = false) { // true: 手动登出；false: 比如401情况强制退出
    cookies.remove('OAM_ID', { domain: '.annto.com' });
    await LogoutApi();
    const loginUrl = config.singlePointLogin + encodeURI(window.location.href);
    const newLocation = window.location;
    if (flag) {
        newLocation.href = config.singlePointLogout + loginUrl;
    } else {
        newLocation.href = loginUrl;
    }
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const date = new Date(time);
    const SEASON_MONTH = 3;
    const YEAR_LENGTH = 4;
    let fmt = cFormat || 'yyyy-MM-dd hh:mm:ss';
    const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + SEASON_MONTH) / SEASON_MONTH) // 季度
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(YEAR_LENGTH - RegExp.$1.length));
    }
    Object.keys(o).forEach(k => {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    });
    return fmt;
}

export function checkAndAlertError(data) {
    const NO_LOGIN = 105;
    const ERROR_STATUS = 1;
    if (data && +data.code !== 0) {
        if (data && +data.code === NO_LOGIN) {
            // alertErrorAndLogout(data.msg || '当前没有登录的用户');
        } else if (data && +data.code === ERROR_STATUS) {
            alertErrorAndLogout('当前票据已失效，请重新登录');
        } else {
            alertErrorAndLogout(data.msg || '接口返回异常');
        }
    }
}

export function alertErrorAndLogout(msg) {
    MessageBox.alert(msg, '错误', {
        confirmButtonText: '确定',
        callback: action => {
            logout();
        }
    });
}

/**
 * @description 获取当前页面的ticket
 */
export function getUrlTicket() {
    const reg = new RegExp('(^|&)' + 'ticket' + '=([^&]*)(&|$)', 'i');
    var result = window.location.search.substr(1).match(reg) || [];
    if (result[2]) {
        return decodeURI(result[2]);
    } else {
        return null;
    }
};

/**
 * @description 判断当前cookies是否有某字符串的键
 */
export function hasCookies(params) {
    var allCookies = document.cookie.split(';');
    allCookies.map((item, index) => {
        var cookieKeys = item.split('=');
        return cookieKeys && cookieKeys[0];
    });
    var res = allCookies.findIndex((value, index, arr) => {
        const result1 = value.indexOf(params);
        return result1 !== -1;
    });
    return res !== -1;
};

/**
 * @description vue实例前判断登录状态，初始登录信息
 */
export async function checkLogin() {
    if (!store.state.currentTenant) {
        await store.dispatch(window.__MICRO_APP_ENVIRONMENT__ ? 'initMicroSystem' : 'initTenantsData').then(res => {
            bigDataLog.sendMainMessage();
            return res;
        });
    }
}

import {
    getUserApplications
} from '@/api/userApi';

export default {
    state: {
        appList: []
    },
    getters: {
        appList: state => state.appList
    },
    mutations: {
        updateAppList(state, _appList) {
            state.appList = _appList;
        }
    },
    actions: {
    /**
     * 获取应用列表
     */
        initAppList({ dispatch, commit, state }) {
            return getUserApplications({
                userCode: state.user.userCode,
                tenantCode: state.tenantInfo.tenantCode
            }).then(res => {
                if (res && +res.code === 0) {
                    commit('updateAppList', res.data);
                }
            });
        }
    }
};

import dayjs from 'dayjs';
import store from '@/store/index';
import { saveUserHitMenuData } from '../lots/api.js';
import { MILLISECOND, SIXTY, TIMEOFDAY } from './constant';
const MAGIC_NUMBER = {
    NEG_THREE: -3,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    TWELVE: 12,
    TWENTY: 20,
    TWENTY_THREE: 23,
    THIRTY: 30,
    THIRTY_ONE: 31,
    THIRTY_SIX: 36,
    FOURTY: 40,
    FOURTY_ONE: 41,
    FIFTY: 50,
    FIFTY_ONE: 51,
    SEVENTY_TWO: 72,
    NINETY: 90,
    TWO_HUNDRED: 200,
    THREE_HUNDRED: 300,
    FOUR_HUNDRED: 400,
    FIVE_HUNDRED: 500,
    SIX_HUNDRED: 600,
    ONE_THOUSAND: 1000,
    ONE_THOUSAND_AND_ONE_HUNDRED: 1100,
    ONE_THOUSAND_AND_FIVE_HUNDRED: 1500,
    TWO_THOUSAND: 2000,
    THREE_THOUSAND: 3000
};
const utils = {
    MAGIC_NUMBER,
    formatDate(date) {
        return this.formatDateTime(date, 'YYYY-MM-DD');
    },
    formatDateTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
        return date ? dayjs(date).format(format) : date;
    },
    ArrMove(arr, index, tindex) { // 数组移动，arr目标数组，index当前下标，tindex目标下标
        arr.splice(tindex, 0, arr.splice(index, 1)[0]);
        return arr;
    },
    /* eslint-enable */
    IE11RouterFix() {
        return {
            methods: {
                _hashChangeHandler: function () {
                    this.$router.push(window.location.hash.substring(1, window.location.hash.length));
                },
                _isIE11: function () {
                    return !!window.MSInputMethodContext && !!document.documentMode;
                },
                _paqClick: function (e) { // 按钮点击事件，发起统计请求,传递点击参数e
                    if (window._paq) {
                        window._paq.push(['trackEvent', `${location.pathname}${this.$route.path}` || 'button', 'Click', e.target.innerText || '']);
                    }
                }
            },
            mounted: function () {
                if (this._isIE11()) {
                    window.addEventListener('hashchange', this._hashChangeHandler);
                }
            },
            destroyed: function () {
                if (this._isIE11()) {
                    window.removeEventListener('hashchange', this._hashChangeHandler);
                }
            }
        };
    },
    /*
    *   判断两个时间戳的差值
    *   @currentDate 当前时间
    *   @previousDate 之前时间
    *   @difference 两个时间的差值(毫秒)，默认值为24小时
    */
    DateDiff(currentDate, previousDate, difference = MILLISECOND * SIXTY * SIXTY * TIMEOFDAY) {
        return currentDate - previousDate < difference;
    },
    setLocalStorage(info, frontUrl, resourceCode) {
        if (!localStorage.getItem('authMenusStatistics')) {
            const storage = this.assembleData(info, frontUrl, resourceCode);
            localStorage.setItem('authMenusStatistics', JSON.stringify(storage));
        } else {
            const authMenusStatistics = JSON.parse(localStorage.getItem('authMenusStatistics'));
            const hasUser = authMenusStatistics.find(item => item.userCode === store.state.user.user.userCode);
            if (!hasUser) {
                const storage = this.assembleData(info, frontUrl, resourceCode);
                localStorage.setItem('authMenusStatistics', JSON.stringify([...authMenusStatistics, ...storage]));
            } else {
                const hasResource = hasUser.resourceDataList.find(item => item.resourceCode === resourceCode);
                if (hasResource) {
                    ++hasResource.actionHit;
                    localStorage.setItem('authMenusStatistics', JSON.stringify(authMenusStatistics));
                } else {
                    const storage = this.assembleData(info, frontUrl, resourceCode);
                    hasUser.resourceDataList = [...hasUser.resourceDataList, ...storage[0].resourceDataList];
                    localStorage.setItem('authMenusStatistics', JSON.stringify(authMenusStatistics));
                }
            }
        }
    },
    assembleData(info, frontUrl, resourceCode) {
        const currentApp = store.state.user.appList.find(item => item.applicationCode === store.state.user.currentApplicationCode);
        const storage = [
            {
                userCode: store.state.user.user.userCode,
                resourceDataList: [
                    {
                        applicationCode: currentApp.applicationCode,
                        applicationName: currentApp.applicationName,
                        aliasName: currentApp.aliasName,
                        resourceCode: resourceCode,
                        path: info,
                        url: frontUrl,
                        actionHit: 1
                    }
                ]
            }
        ];
        return storage;
    },
    sendMenuData() {
        const authMenusStatistics = JSON.parse(localStorage.getItem('authMenusStatistics'));
        if (authMenusStatistics) {
            saveUserHitMenuData(authMenusStatistics).then(res => {
                if (+res.code === 0) {
                    localStorage.removeItem('authMenusStatistics');
                }
            });
        }
    },
    // 产生唯一字符串
    /* eslint-disable no-magic-numbers */
    uuid() {
        const s = [];
        const hexDigits = '0123456789abcdef';
        const safeRandom = () => {
            const random = new Uint32Array(1);
            // eslint-disable-next-line no-magic-numbers
            return crypto.getRandomValues(random)[0] / 0xffffffff;
        };
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(safeRandom() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    },
    // 日期选择器获取时间天数范围，返回时间范围时间戳数组
    defaultDate(day = 15) {
        let start, end;
        if (+day >= 0) {
            const monthTamp = 60 * 60 * 24 * 1000 * +day + +'86399000';
            end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
            start = new Date();
            start.setTime(end - monthTamp);
        } else {
            start = new Date().setHours(0, 0, 0, 0);
            end = new Date(start + 60 * 60 * 24 * 1000 * +Math.abs(day) + +'86399000');
        }
        return [start, end];
    }
};
export default utils;

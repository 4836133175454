/***
 * 说明文档：
 * 对elementui的message组件的扩展,添加关闭按钮，其他使用方式一致
 * 调用方式：
 * this.$lcpMessage() | this.$lcpMessage.success()
 */
import Vue from 'vue';
import { Message } from 'element-ui';
export const lcpMessage = function(options) {
    options = options || {};
    if (typeof options === 'string') {
        options = {
            message: options,
            showClose: true
        };
    } else {
        options = Object.assign({}, { showClose: true }, options);
    }
    Message(options);
};
['success', 'warning', 'info', 'error'].forEach(function (type) {
    lcpMessage[type] = function (options) {
        if (typeof options === 'string') {
            options = {
                message: options,
                showClose: true
            };
        } else {
            options = Object.assign({}, { showClose: true }, options);
        }
        options.type = type;
        return lcpMessage(options);
    };
});
lcpMessage.closeAll = Message.closeAll;
Vue.prototype.$lcpMessage = lcpMessage;

import { render, staticRenderFns } from "./Msg.vue?vue&type=template&id=3b88e19d"
import script from "./Msg.vue?vue&type=script&lang=js"
export * from "./Msg.vue?vue&type=script&lang=js"
import style0 from "./Msg.vue?vue&type=style&index=0&id=3b88e19d&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
/***
 * 说明文档：
 * - 控制页面的keepAlive
 * 前提条件：
 * -  要控制keepAlive切换的页面必须有name属性，且name属性必须唯一
 * 使用方法：
 * - 在页面跳转之前，加入以下代码：
 * - this.removeKeepAlivePage('clientOrderDetail'); // 参数为指定的页面的name属性的值
 * - 即可。
 * 注意事项：
 * - 在页面跳转之后，建议通过
 * - this.insertKeepAlivePage('clientOrderDetail');
 * - 把页面缓存重新加回来，不然之后跳转到该页面都不会缓存。但这不是固定的，最终根据需求灵活使用。
 */
import Vue from 'vue';
import _ from 'lodash';
export const notAlivePageNames = new Set();
export const changeFlag = { flag: 1 };

export function initKeepAliveCfg() {
    Vue.mixin({
        methods: {
            insertKeepAlivePage(name) {
                changeFlag.flag++;
                _.isString(name) && notAlivePageNames.delete(name);
            },
            removeKeepAlivePage(name) {
                changeFlag.flag++;
                _.isString(name) && notAlivePageNames.add(name);
            }
        }
    });
};

<template>
    <el-dropdown ref="dropdown" class="service" trigger="click" @visible-change="visibleChange" :hide-on-click="false">
        <div class="dropdown-title">
            <el-button type="text" class="icon iconfont icon-button icon-fixed-size">&#xe674;</el-button>
            <span style="font-size: 12px;">{{this.text}}</span>
        </div>
        <el-dropdown-menu slot="dropdown" class="app-list-switch">
            <el-input
                v-model="serachText"
                @input="handleEnter"
                ref="input"
                size="medium"
                placeholder="请输入搜索内容"
                class="serach-input">
            </el-input>
            <a target="_blank"
                v-for="item in copyAppList"
                @click="goCopyAppListPath(item)"
                :key="item.applicationCode">
                <el-dropdown-item name="system">{{item.applicationName}}</el-dropdown-item>
            </a>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import config from '@/config/user.env';
export default {
    data() {
        return {
            copyAppList: [],
            serachText: ''
        };
    },
    computed: {
        text() {
            const currentPath = this.appList.find(item => {
                if (item.applicationDomain) {
                    return item.applicationCode === this.$store.state.user.currentApplicationCode;
                }
                return false;
            });
            if (currentPath && currentPath.applicationName) {
                return currentPath.applicationName;
            } else {
                return '';
            }
        },
        ...mapGetters(['appList'])
    },
    methods: {
        visibleChange(flag) {
            if (!flag) {
                this.serachText = '';
                this.handleEnter();
            } else {
                this.executeDropdown();
                this.copyAppList = JSON.parse(JSON.stringify(this.appList));
            }
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        handleEnter(e) {
            // e && e.stopPropagation();
            if (this.serachText) {
                this.copyAppList = this.appList.filter(item => {
                    return item.applicationName.toUpperCase().indexOf(this.serachText.toUpperCase()) !== -1;
                });
            } else {
                this.copyAppList = this.appList;
            }
            this.$refs.dropdown.removeTabindex();
            this.$nextTick(() => {
                this.executeDropdown();
            });
        },
        getAppList() {
            this.initAppList().then(res => {
                this.$nextTick(() => {
                    this.copyAppList = JSON.parse(JSON.stringify(this.appList));
                });
            });
        },
        goCopyAppListPath(item) {
            const env = config.env.toLowerCase();
            const isProd = env !== 'prod';
            const anGopath = isProd ? 'an-' + env + '.midea.com' : 'an.midea.com';
            const authGopath = isProd ? 'auth-' + env + '.midea.com' : 'auth.midea.com';
            const lcGopath = isProd ? 'l' + env + '.midea.com' : 'lc.midea.com';
            let pathUrl = '';
            const application = item.applicationDomain || '';
            if (item.customDomain) {
                if (item.customDomain.indexOf('//') > -1) {
                    pathUrl = item.customDomain + application;
                } else {
                    pathUrl = '//' + item.customDomain + application;
                }
            } else if (document.domain === anGopath || document.domain === authGopath) {
                pathUrl = this.dealPathUrl(application, document.domain);
            } else {
                pathUrl = this.dealPathUrl(application, lcGopath);
            }
            this.openNewPath(pathUrl);
        },
        openNewPath(pathUrl) {
            var el = document.createElement('a');
            document.body.appendChild(el);
            el.target = '_blank'; // 指定在新窗口打开
            el.href = pathUrl;
            el.click();
            document.body.removeChild(el);
        },
        dealPathUrl(application, getPathDomain) {
            if (application && application.indexOf('//') > -1) {
                return application;
            } else if (application && application.charAt(0) !== '/') {
                return '//' + getPathDomain + '/' + application;
            } else {
                return '//' + getPathDomain + application;
            }
        },
        executeDropdown() {
            // 下拉菜单组件获取最新数据
            this.$refs.dropdown.initDomOperation();
            this.$refs.dropdown.triggerElm.removeEventListener('keydown', this.$refs.dropdown.handleTriggerKeyDown);
        },
        ...mapActions([
            'initAppList'
        ])
    },
    created() {
        this.getAppList();
    },
    mounted() {
        this.$refs.dropdown.triggerElm.removeEventListener('keydown', this.$refs.dropdown.handleTriggerKeyDown);
        // this.$refs.dropdown
    }
};
</script>
<style lang="less">
    .service{
        cursor: pointer;
        margin-right: 20px;
        .dropdown-title{
            &:focus{
                outline: 0;
            }
            .dropdown-img{
                font-size: 20px;
                margin-right: 8px;
                vertical-align: middle;
            }
        }
    }
    .app-list-switch{
        >a{
            text-decoration: none;
        }
        .serach-input {
            .el-input__inner {
                border: 0;
                border-bottom: 1px solid #DCDFE6;
            }
        }
        background: #FFF;
        .el-dropdown-menu__item{
            width: 230px;
            color: #555757;
            line-height: 36px !important;
            font-size: 12px !important;
            padding: 0 20px !important;
            box-sizing: border-box;
            .rightIcon {
                position: absolute;
                left: 15px;
                top: 0;
            }
        }
        .el-dropdown-menu__item:hover{
            background: #EEE;
            color: #555757;;
        }

    }

</style>

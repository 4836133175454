<template>
    <svg class="lots-icon" aria-hidden="true">
        <use :xlink:href="`#icon-${name}`" />
    </svg>
</template>

<script>
export default {
    name: 'lotsIcon',
    props: {
        name: { // svg文件名称
            type: String,
            required: true
        }
    }
};
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

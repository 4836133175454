/**
* Created by linxx7 on 2020-2-18
*/
<script>
import _ from 'lodash';
import { I18nMixins } from '@/components/mixins/i18n';
export default {
    name: 'SidebarItem',
    mixins: [I18nMixins],
    props: {
        menu: {
            type: Object,
            default () {
                return {};
            }
        },
        collapse: {
            default () {
                return false;
            }
        },
        isSubMenu: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            outerLinkRegExp: 'http'
        };
    },
    methods: {
        goToLink(url) {
            if (_.startsWith(url, this.outerLinkRegExp)) {
                window.open(url);
            }
        },
        routerPath(url) {
            return _.startsWith(url, this.outerLinkRegExp) ? '' : url;
        },
        renderMenu() {
            const subResources = this.menu.subResources || [];
            const hasIcon = !!this.menu.resourceUrl;
            // 下面用了div来包裹el-submenu只是为了解决elementui导航栏堆栈调用溢出的bug，issue地址：https://github.com/ElemeFE/element/issues/17391
            return (
                <div>
                    <el-submenu index={this.menu.resourceCode} resourceCode={this.menu.resourceCode}>
                        <template slot="title">
                            { hasIcon && <em class={this.menu.resourceUrl.indexOf('icon-') > -1 ? `iconfont ${this.menu.resourceUrl} customIcon` : 'iconfont icon-gather customIcon'}></em> }
                            <span slot="title"
                                title={this.$translate(`lang.${this.menu.resourceCode}`, { defaultText: this.menu.resourceName })}
                                class={this.isSubMenu ? 'heighLight6' : 'heighLight8'}>
                                {this.$translate(`lang.${this.menu.resourceCode}`, { defaultText: this.menu.resourceName })}
                            </span>
                        </template>
                        {
                            subResources.map(sub => {
                                return <sidebar-item menu={sub} isSubMenu></sidebar-item>;
                            })
                        }
                    </el-submenu>
                </div>
            );
        },
        renderSubMenu() {
            const hasIcon = !!this.menu.resourceUrl;
            const path = this.routerPath(this.menu.frontUrl);
            // 如果路径录入时，扩展属性为lcp-hidden，则不在左侧导航栏中出现，用于详情页等不需要展示在导航栏的页面使用
            if (this.menu.extendAttr1 === 'lcp-hidden') return '';
            const { resourceCode, resourceName } = this.menu;
            return resourceCode && resourceName ? (
                // eslint-disable-next-line max-len
                <el-menu-item index={path} class={{ 'isSub': this.$route.path === this.menu.frontUrl }} onClick={() => this.goToLink(this.menu.frontUrl)} resourceCode={this.menu.resourceCode} frontUrl={this.menu.frontUrl}>
                    { hasIcon && <em class={this.menu.resourceUrl.indexOf('icon-') > -1 ? `iconfont ${this.menu.resourceUrl} menuIcon` : 'el-icon-menu'}></em> }
                    <span slot="title" title={this.$translate(`lang.${resourceCode}`, { defaultText: resourceName })} class={this.isSubMenu ? 'heighLight6' : 'heighLight8'}>
                        {this.$translate(`lang.${resourceCode}`, { defaultText: resourceName })}
                    </span>
                </el-menu-item>
            ) : null;
        }
    },
    render() {
        const PAGETYPE = 2; // 1菜单，2页面，3元素
        if (this.menu.resourceType === PAGETYPE) {
            return this.renderSubMenu();
        } else {
            return this.renderMenu();
        }
    }
};
</script>
<style lang="less">
.side-bar-menu{
    .el-submenu__title{
        .customIcon{
            margin-right: 10px;
            margin-left: -3px;
            font-size: 18px;
        }
        .heighLight6 {
            color: rgba(255, 255, 255, .6);
        }
        .heighLight8{
            color: rgba(255, 255, 255, .8);
        }
    }
    .el-menu-item{
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .heighLight6 {
            color: rgba(255, 255, 255, .6);
        }
        .heighLight8{
            color: rgba(255, 255, 255, .8);
        }
        .menuIcon {
            margin-right: 10px;
            margin-left: -3px;
            font-size: 16px;
            color: rgba(255, 255, 255, .6);
        }
        &.isSub i {
            color: rgba(255, 255, 255, .8);
        }
    }
    .isSub{
        background-color: #4285F5 !important;
        .heighLight6 {
            color: #fff;
        }
    }
    .el-submenu__title:hover{
        background-color: #15161A !important;
    }
    .el-menu-item:hover{
        background-color: #15161A;
    }
    .defaultBg{
        background-color: #15161A !important;
    }
    &.el-menu--collapse .el-submenu > .el-submenu__title {     text-overflow: clip; }
}

</style>

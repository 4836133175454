<template>
    <el-button type="text" class="icon iconfont icon-button icon-fixed-size" @click="handleMsgCommand">
        &#xe6bb;
        <el-drawer
            :title="$translate('lang.E202006240013', { defaultText: '最新消息' })"
            append-to-body
            destroy-on-close
            :before-close="handleDrawerClose"
            size="23%"
            custom-class="msg-drawer"
            :visible.sync="drawer">
            <div class="radio">
                <el-button type="primary" plain size="mini" @click="handleClickWarning" :class="{'isActivied': radio === '1'}">{{$translate('lang.E202006240014', { defaultText: '预警信息' })}}</el-button>
                <el-button type="primary" plain size="mini" @click="handleClickNotice" :class="{'isActivied': radio === '2'}">{{$translate('lang.E202006240017', { defaultText: '公告信息' })}}</el-button>
            </div>
            <div
                v-if="radio === '1'"
                class="dropdown-items-wrapper"
                v-infinite-scroll="() => {
                    _getWarningList();
                }"
                :infinite-scroll-disabled="warningDisabled">
                <div
                    class="el-dropdown-menu__item"
                    v-for="(item, index) in msgList.warning.list"
                    @click="() => {
                        item.isRead = true;
                        handleMessage(item)
                    }"
                    :key="index"
                >
                    <div class="warning-item">
                        <span>[{{$translate('lang.E202006240014', { defaultText: '预警信息' })}}]</span>
                        <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div :class="{'title': true, 'isRead': item.isRead}">{{ item.title }}</div>
                </div>
                <div class="message-more" command="warning-more" v-if="warningLoading">
                    <div class="more">{{$translate('lang.E202006240015', { defaultText: '加载中' })}}...</div>
                </div>
                <div class="message-more" command="warning-more" v-if="warningHasMore">
                    <div class="more">{{$translate('lang.E202006240016', { defaultText: '没有更多了' })}}</div>
                </div>
            </div>
            <div
                v-if="radio === '2'"
                class="dropdown-items-wrapper"
                v-infinite-scroll="() => {
                    _getNoticeList();
                }"
                :infinite-scroll-disabled="noticeDisabled">
                <div
                    class="el-dropdown-menu__item"
                    v-for="(item, index) in msgList.notice.list"
                    @click="() => {
                        item.isRead = true;
                        handleMessage(item)
                    }"
                    :key="index"
                >
                    <div class="warning-item">
                        <span>[{{$translate('lang.E202006240017', { defaultText: '公告信息' })}}]</span>
                        <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div :class="{'title': true, 'isRead': item.isRead}">{{ item.title }}</div>
                </div>
                <div class="message-more" command="message-more" v-if="noticeLoading">
                    <div class="more">{{$translate('lang.E202006240015', { defaultText: '加载中' })}}...</div>
                </div>
                <div class="message-more" command="message-more" v-if="noticeHasMore">
                    <div class="more">{{$translate('lang.E202006240016', { defaultText: '没有更多了' })}}</div>
                </div>
            </div>
        </el-drawer>
        <msg-dialog
            ref="msgDialog"
            :data="msgDialogData"
        />
    </el-button>
</template>

<script>
import MsgDialog from '../MsgDialog';
import dayjs from 'dayjs';
import { mapGetters, mapActions } from 'vuex';
import { I18nMixins } from '@/components/mixins/i18n';
export default {
    mixins: [I18nMixins],
    components: {
        MsgDialog
    },
    computed: {
        warningDisabled() {
            return this.warningHasMore || this.warningLoading;
        },
        noticeDisabled() {
            return this.noticeHasMore || this.noticeLoading;
        },
        ...mapGetters(['applicationCode', 'msgList'])
    },
    data() {
        return {
            msgDialogData: {},
            radio: '1',
            drawer: false,
            warningType: ['', '公告消息', '预警消息'], // 预警类型
            noticeType: ['', '公告', '预警', '维护', '更新'], // 信息类型
            listPageSize: 10,
            warningHasMore: false,
            noticeHasMore: false,
            warningLoading: false,
            noticeLoading: false
        };
    },
    methods: {
        handleMessage(item) {
            this.msgDialogData = item;
            this.$refs.msgDialog.handleVisible(true);
        },
        handleClickWarning() {
            this.warningHasMore = false;
            this.warningLoading = false;
            this.radio = '1';
            this._getWarningList(true);
        },
        handleClickNotice() {
            this.noticeHasMore = false;
            this.noticeLoading = false;
            this.radio = '2';
            this._getNoticeList(true);
        },
        handleDrawerClose() {
            this.drawer = false;
        },
        handleMsgCommand() {
            this.drawer = true;
        },
        _getWarningList(flag) {
            if (this.warningHasMore) {
                return;
            }
            const startlt = dayjs().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
            const endgt = dayjs().format('YYYY-MM-DD HH:mm:ss');
            this.warningLoading = true;
            this.getWarningList({
                startlt,
                endgt,
                flag,
                pageSize: this.listPageSize
            }).then(res => {
                if (res) {
                    this.warningHasMore = true;
                    this.warningLoading = false;
                }
            });
        },
        _getNoticeList(flag) {
            if (this.noticeHasMore) {
                return;
            }
            const startlt = dayjs().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss');
            const endgt = null;
            this.noticeLoading = true;
            this.getNoticeList({
                startlt,
                endgt,
                flag,
                pageSize: this.listPageSize
            }).then(res => {
                if (res) {
                    this.noticeHasMore = true;
                    this.noticeLoading = false;
                }
            });
        },
        ...mapActions([
            'getWarningList',
            'getNoticeList'
        ])
    }
};
</script>

<style lang="less">
.msg-drawer{
    &:focus{
        outline: none;
    }
    .el-drawer__header{
        border-bottom: 1px solid #ccc;
        padding: 15px;
        margin: 0;
        .el-drawer__close-btn{
            &:focus{
                outline: none;
            }
        }
        & > span{
            &:focus{
                outline: none;
            }
        }
    }
    .el-drawer__body{
        display: flex;
        flex-direction: column;
        &.message-more {
            padding: 12px 10px;
            text-align: center;
        }
        .warning-item{
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
            padding-bottom: 5px;
            padding-top: 10px;
            color: #666666
        }
        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333333;
        }
        .isRead{
            color: #999999;
        }
        .tag {
            color: #00BCBF;
            &.warning-tag-2, &.message-tag-2 {
                color: #F96C5F;
            }
        }
        .time {
            font-size: 12px;
            color: #666666;
            padding-left: 5px;
        }
    }
    .radio{
        padding: 15px 20px;
        .el-button{
            border-radius: 4px;
            background: #F5F5F5;
            color: #999999;
            border-color: #F5F5F5;
            &:hover{
                border-color: none;
                color: none;
            }
        }
        .isActivied{
            color: #333333;
            background: rgba(66,133,245,0.10);
            border: 1px solid #4285F5;
        }
    }
    .dropdown-items-wrapper {
        overflow-y: auto;
        .message-more {
            border-top: 1px solid #f0f0f0;
            margin: 0 20px;
            padding: 12px 0;
            text-align: center;
        }
    }
    .el-dropdown-menu__item {
        padding: 0 20px 10px 20px;
        font-size: 13px;
        line-height: 18px;
        &:hover{
            background: #F0F0F0;
        }
    }
}
</style>

/**
 *  公共组件所需的接口api文件
 *  @module lots/api
 */
import localforage from 'localforage';
import _ from 'lodash';
import request from '@/utils/http';
import { getSession, setSession, getLocal, setLocal } from '@/components/utils/storage';
import store from '@/store';
import utils from '../utils/common';
import DictQueue from './DictQueue';
import { MILLISECOND, SIXTY, TIMEOFDAY } from '../utils/constant';
const LINKSYM = '#';
/**
 * 省市区相关接口
 */

/** 初始化indexedDb数据表 */
const addressDb = localforage.createInstance({
    storeName: 'address'
});

/**
 * 获取省市区区域数据
 * @function getAreaApi
 * @static
 * @param {Object} params 获取区域数据的参数，详看 /api-mdm/searchEbPlace 接口文档
 * @returns {Object} 响应数据
 */
export const getAreaApi = async (params, difference = MILLISECOND * SIXTY * SIXTY * TIMEOFDAY) => {
    const currentTimestamp = new Date().getTime();
    const areaList = await addressDb.getItem(String(params.ebplParentPmCode));
    if (areaList && areaList.time && utils.DateDiff(currentTimestamp, areaList.time, difference)) {
        return {
            code: '0',
            data: areaList.data
        };
    } else {
        params.ebplIsAble = 'ENABLE';
        return request({
            url: '/api-mdm/searchEbPlace',
            method: 'get',
            params: params
        }).then(res => {
            if (res && res.code === '0' && !_.isEmpty(res.data)) {
                addressDb.setItem(String(params.ebplParentPmCode), {
                    time: new Date().getTime(),
                    data: res.data
                });
            }
            return res;
        });
    }
};

/**
 * 列配置相关接口
 */

/** 列配置和自定义搜索条件配置相关接口 */
export const getList = {
    post(url, params) {
        const key = [params.id, params.subjectType, params.moduleCode].join(LINKSYM);
        Reflect.deleteProperty(params, 'id');
        setLocal(key, params.configName);
        return request({
            url: url + '/userIndividualConfigs/saveOrUpdate',
            method: 'post',
            data: params
        });
    },
    get(url, params) {
        const key = [params.id, params.subjectType, params.moduleCode].join(LINKSYM);
        Reflect.deleteProperty(params, 'id');
        if (getLocal(key)) {
            return Promise.resolve({
                code: '0',
                data: {
                    configName: getLocal(key)
                }
            });
        } else {
            return request({
                url: url + '/userIndividualConfigs/param',
                method: 'post',
                data: params
            }).then(res => {
                if (res.code === '0' && res.data.configName) {
                    setLocal(key, res.data.configName);
                }
                return res;
            });
        }
    }
};

/** 列配置和自定义搜索条件配置相关接口2 */
export const fieldConfig = {
    set: (params) => {
        return getList.post('/api-auth', params);
    },
    get: (params) => {
        return getList.get('/api-auth', params);
    }
};

/**
 *  数据字典相关接口
 */

/** 获取缓存字典
 *  @param {string} keyStr 数据字典key串，可批量用,分隔
 *  @returns {Object} 返回key值跟对应的字典内容
 */
function getDictCache(keysStr) {
    if (!keysStr) {
        return {
            result: {},
            passKeys: ''
        };
    }

    const tenantCode = store.getters.currentTenant.tenantCode;
    const locale = store.getters.locale || 'zh-CN';
    const keys = keysStr.split(',');
    const result = {};
    const passKeys = [];
    keys.forEach(key => {
        const sessionKey = [tenantCode, locale, key].join(LINKSYM);
        const val = getSession(sessionKey);
        if (val) {
            result[key] = val;
        } else {
            passKeys.push(key);
        }
    });
    return {
        result,
        passKeys: passKeys.join(',')
    };
}
/** 设置字典缓存
 *  @param {Object} map key-value映射对象
 */
function setDictCache(map) {
    const tenantCode = store.getters.currentTenant.tenantCode;
    const locale = store.getters.locale || 'zh-CN';
    Object.keys(map).forEach(key => {
        if (_.isEmpty(map[key])) return;
        const sessionKey = [tenantCode, locale, key].join(LINKSYM);
        setSession(sessionKey, map[key]);
    });
}
/**
 * 字典获取函数
 * @function dict
 * @static
 * @param {String} popPrefix 字典接口前缀
 * @param {Object|String} params 字典参数
 * @returns {Promise<Object>} 响应结果
 */
export const dict = (popPrefix, params) => {
    let dictCodes = null;
    if (_.isString(params)) {
        dictCodes = params;
    } else {
        dictCodes = params.dictCodes;
    }
    const data = getDictCache(dictCodes);
    if (data.passKeys === '') {
        return new Promise(resolve => {
            resolve({
                code: '0',
                data: data.result
            });
        });
    } else {
        return request({
            url: popPrefix,
            method: 'get',
            params: {
                // 不区分租户
                tenantCode: store.getters.currentTenant.tenantCode,
                applicationCode: params.applicationCode || store.getters.currentTenant.applicationCode || store.getters.currentApplicationCode,
                dictCodes: data.passKeys
            }
        }).then(res => {
            setDictCache(res.data);
            res.data = { ...data.result, ...res.data };
            return res;
        });
    }
};

/** 初始化队列对象 */
const dictQueue = new DictQueue();

/**
 * 获取数据字典（带队列版）
 * @function dictWithUrl
 * @static
 * @param {Object|string} params 数据字典参数
 * @param {string} url 数据字典接口地址
 * @returns {Promise<Object>} 返回队列promise
 */
export const dictWithUrl = (params, url = '/api-mdm/dictionaryDetail/dict') => {
    dictQueue.setFetchFn((params) => dict(url, params));
    const dictCodes = _.isString(params) ? params : params.dictCodes;
    return dictQueue.queueUp(dictCodes, url);
};
// 根据字典编码，key获取name
export const dictNameByCode = (code, value, url) => {
    return new Promise((resolve) => {
        let dictName = '';
        dictWithUrl(code, url).then(res => {
            if (res.data && res.data[code]) {
                Object.keys(res.data[code]).forEach(key => {
                    if ((key + '') === (value + '')) {
                        dictName = res.data[code][key];
                    }
                });
            }
            resolve(dictName);
        });
    });
};
/**
 *  保存常用菜单接口
 */
export const saveUserHitMenuData = (data) => {
    return request({
        url: `/api-auth/saveUserHitMenuData`,
        method: 'post',
        data
    }).then(res => res);
};

/**
* Created by linxx7 on 2020-2-13.
*/
<template>
    <keep-alive>
    <el-menu class="side-bar-menu" @open="handleOpen" @close="handleClose" :collapse="isCollapse" ref="elMenu"
        background-color="#1e1e28" text-color="#fff" active-text-color="#fff" :unique-opened="true" :router="true">
        <template v-for="(menu, index) in menus">
            <sidebar-item :menu="menu" :key="index + menu.resourceCode"></sidebar-item>
        </template>
    </el-menu>
    </keep-alive>
</template>
<script>
import SidebarItem from './SidebarItem';
export default {
    name: 'CoreSidebarMenus',
    components: {
        SidebarItem
    },
    props: {
        menus: {
            type: Array,
            default () {
                return [];
            }
        },
        collapse: {
            type: Boolean,
            default () {
                return true;
            }
        }
    },
    data() {
        return {
        };
    },
    watch: {
        $route(to, from) {
            const { submenus, items } = this.$refs.elMenu;
            for (const key in submenus) { // 先遍历所有有子菜单的项，去掉defaultBg
                if (!submenus[key].items[to.path]) {
                    submenus[key].$refs['submenu-title'].classList.remove('defaultBg');
                }
            }
            if (items && items[to.path]) {
                this.traversalParent(items[to.path].parentMenu); // 递归父菜单设置高亮
            }
        }
    },
    computed: {
        isCollapse() {
            return this.collapse;
        }
    },
    methods: {
        handleOpen(key, keyPath) {},
        handleClose(key, keyPath) {},
        traversalParent(item) {
            if (!item.$refs['submenu-title']) return false;
            item.$refs['submenu-title'].classList.add('defaultBg');
            if (item.parentMenu) {
                this.traversalParent(item.parentMenu);
            }
        }
    }
};
</script>
<style lang="less">
.el-menu--collapse > div > .el-submenu > .el-submenu__title span,
.el-menu--collapse
  > div
  > .el-submenu
  > .el-submenu__title
  .el-submenu__icon-arrow {
  display: none;
}
.side-bar-menu {
    &.el-menu {
        width: 200px;
        border-right: 0 none;
    }
    .el-submenu > .el-submenu__title{
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.el-menu--collapse {
        width: 50px;
    }
}
.defaultBg{
    background-color: #15161A !important;
}
</style>

<template>
    <el-dropdown-menu slot="dropdown" class="file-list">
        <div  class="user-file-list">
            <div class="header-wrap">
                <h4 class="title" @click="toAll">文件信息</h4>
                <el-button class="refresh" icon="el-icon-refresh" @click="refresh"></el-button>
            </div>
            <div class="content-wrap">
                <el-table border :data='displayData'
                    v-loadmore="loadMoreList"
                    ref="dataTable"
                    :height="235"
                    v-loading="fileList.loading">
                    <el-table-column
                        v-for="(item, index) in fileList.columns"
                        :key="index"
                        :prop="item.prop"
                        :label="item.label"
                        :width="item.width"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :disabled="scope.row.remark? false: true"   :content="scope.row.remark||''" placement="top">
                            <div
                                class="status"
                                v-if="item.prop === 'fileStatus'">
                                <div :class="['point', scope.row.fileStatusPoint]"></div>
                                {{scope.row.fileStatusText}}
                            </div>
                            <span v-else>{{scope.row[item.prop]}}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="action" width="60" label='操作'>
                        <template slot-scope="scope">
                            <el-button
                                v-if="displayDownBtn(scope.row, scope.$index)"
                                @click.native.prevent="downLoad(scope.row, scope.$index)"
                                type="text"
                                size="small">
                                下载
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- 加载更多提示 -->
                    <template slot="append">
                        <div
                            class="loadMoreText"
                            v-html="fileList.loadMoreText">
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </el-dropdown-menu>
</template>

<script>
/* eslint-disable no-magic-numbers */
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    computed: {
        displayData() { // 显示在表格中的数据
            const newData = [];
            this.fileList.tableData.forEach((item) => { // 从实际数据提取处理
                const newItem = JSON.parse(JSON.stringify(item));
                if (newItem['fileStatus'] === null) {
                    newItem['fileStatusPoint'] = `blue`; newItem['fileStatusText'] = `已生成`;
                } else {
                    switch (newItem['fileStatus']) {
                    case 1: newItem['fileStatusPoint'] = `green`; newItem['fileStatusText'] = `已提交`; break;
                    case 2: newItem['fileStatusPoint'] = `orange`; newItem['fileStatusText'] = `生成中`; break;
                    case 3: newItem['fileStatusPoint'] = `blue`; newItem['fileStatusText'] = `已生成`; break;
                    case 4: newItem['fileStatusPoint'] = `red`; newItem['fileStatusText'] = `失败`; break;
                    default: newItem['fileStatusPoint'] = ``; newItem['fileStatusText'] = ``; break;
                    };
                }
                newData.push(newItem);
            });
            return newData;
        },
        ...mapGetters(['fileList'])
    },
    directives: { // 自定义指令
        loadmore: { // 监听element-ui表格滚动到底部
            bind(el, binding) {
                const selectWrap = el.querySelector('.el-table__body-wrapper');
                selectWrap.addEventListener('scroll', function() {
                    const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
                    binding.value(scrollDistance);
                });
            }
        }
    },
    methods: {
        ...mapMutations({
            updateFileList: 'updateFileList'
        }),
        ...mapActions([
            'getFileList'
        ]),
        loadMoreList(scrollDistance) { // 表格下拉加载
            if (scrollDistance === 0 && !this.fileList.loadMoreFinished && !this.fileList.loadMoreStatus) {
                this.updateFileList({
                    ...this.fileList,
                    loadMoreText: '<em class="el-icon-loading"></em>加载中...'
                });
                this.getUserFileList('add');
            }
        },
        getUserFileList(val) { // 获取用户文件列表
            this.getFileList({ val });
        },
        displayDownBtn(row, index) { // 显示下载按钮
            return row['fileStatusText'] === `已生成`;
        },
        downLoad(row, index) { // 下载某个文件
            window.open(row.downUrl + '?pro=https', '_blank');
        },
        refresh() { // 刷新操作
            this.getUserFileList();
        },
        toAll() { // 跳转全部文件列表（详情）
            const origin = window.location.origin;
            window.open(`${origin}/mdm.html#/oss-file`, '_new');
        }
    },
    mounted() {
        this.getUserFileList();
    }
};
</script>
<style lang="less">
.file-list{
    background: #FFF;
}
.user-file-list {
    background: #FFF;
    padding: 5px 15px;
    .header-wrap {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        .title {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
        .refresh {
            padding: 6px;
            i { font-size: 16px; }
        }
    }
    .content-wrap {
        width: 478px;
        margin-top: 10px;
        border: 1px solid #eee;
        .el-table td { height: 42px; }
        .status {
            line-height: 16px;
            .point {
                background: #eee;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-right: 5px;
                &.green { background: #00d082; }
                &.orange { background: #ff8800; }
                &.blue { background: #3a8ee6; }
                &.red { background: #ed0000; }
            }
        }
        .loadMoreText {
            text-align: center;
            margin: 1rem 0;
        }
    }
}
</style>

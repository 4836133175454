/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
import langApi from './api/lang';
// 自定义
import loadModuleLang from './components/index';

// 标准语言代码对应element ui语言包映射
const elementLangMap = {
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW',
    'zh-HK': 'zh-TW',
    'ru-RU': 'ru-RU',
    'en-US': 'en',
    'ja-JP': 'ja',
    'ko-KR': 'ko'
};
// 现有代码组件语言包映射对标准语言码的映射
const componentLangMap = {
    'zh-CN': 'zh',
    'en-US': 'en',
    'ja-JP': 'ja'
};

/**
 * 按照语言动态加载对应的Element组件Lang包
 * @param elementLangs
 */
const loadElementLang = async (elementLangs) => {
    const loadLangList = elementLangs.slice(0);
    const allLoadElementsLang = elementLangs.map(modName => {
        return import(`element-ui/src/locale/lang/${elementLangMap[modName]}`);
    });
    return Promise.all(allLoadElementsLang).then(res => {
        const elementLangs = {};
        for (let i = 0; i < res.length; i++) {
            const langName = loadLangList[i];
            elementLangs[langName] = res[i].default;
        }
        return elementLangs;
    });
};
/**
 * 获取多个系统下的语言包，放入不同的命名空间，注意不要和现有的命名空间相同，否则会覆盖
 * @param appList Array
 * @param lang  标准语言码 zh-CN
 * @returns {Promise<void>}
 * {
 *   nameSpace1:{key1:value1}
 *   nameSpace2:{key1:value1}
 * }
 */
const multilangSwitch = false; // 多语言开关
const getServerLangInfo = async (appList, lang) => {
    if (!multilangSwitch) {
        store.commit('updateLocale', 'zh-CN');
        return {};
    }
    // 需要加载的语言包信息
    const appArr = appList.slice(0);
    // 取缓存
    const localCache = JSON.parse(localStorage.getItem('langInfo')) || {};
    // console.log('localCache', localCache);
    // let RemoteLangCache = {};
    for (let i = 0; i < appArr.length; i++) {
        if (localCache.hasOwnProperty(lang)) {
            if (localCache[lang].hasOwnProperty(appArr[i].nameSpace)) {
                // RemoteLangCache = localCache[lang][appArr[i].nameSpace];
                if (localCache[lang][appArr[i].nameSpace].versionCode) {
                    appArr[i].versionCode = localCache[lang][appArr[i].nameSpace].versionCode;
                }
                if (localCache[lang][appArr[i].nameSpace].list) {
                    appArr[i].langInfo = localCache[lang][appArr[i].nameSpace].list;
                }
            }
        }
        // const LangVersion = RemoteLangCache.versionCode ? RemoteLangCache.versionCode : 0;
    }

    // console.log('RemoteLangCache', RemoteLangCache);
    // console.log('appArr', appArr);
    const ServerLangPromiseArr = appArr.map(oneApp => {
        const params = {
            versionCode: oneApp.versionCode,
            langCode: oneApp.langCode,
            applicationCode: oneApp.applicationCode
        };
        return langApi.getLang(params).catch(res => {
            return {};
        });
    });
    const ServerLangInfo = await Promise.all(ServerLangPromiseArr).catch(res => {
        return [];
    });
    // console.log('ServerLangInfo', ServerLangInfo);
    // 所有系统下的语言包
    const localeMessage = {};
    for (let i = 0; i < ServerLangInfo.length; i++) {
        // 如果没有接口信息，则跳过以防报错
        if (!ServerLangInfo[i].data) return;
        const langData = ServerLangInfo[i].data;

        if (langData.list) {
            langData.backList = langData.list.slice(0);
            const langDataList = {};
            // 语言数据转key-value对应
            for (let i = 0; i < langData.list.length; i++) {
                langDataList[langData.list[i].code] = langData.list[i].content || '';
            }
            langData.list = langDataList;
        }
        // console.log('langData', langData);
        let langInfo = null;

        if (langData.versionCode && (langData.versionCode !== appArr[i].langVersion) && (langData.backList.length > 0)) {
            // 更新语言包
            delete langData.backList;
            // alert('cc');
            if (!localCache[lang]) {
                localCache[lang] = {};
            }
            localCache[lang][appArr[i].nameSpace] = langData;
            // console.log('setlocalCache', localCache);
            localStorage.setItem('langInfo', JSON.stringify(localCache));
            langInfo = langData.list;
        } else {
            langInfo = appArr[i].langInfo || {};
        }
        localeMessage[appArr[i].nameSpace] = langInfo;
    }
    // console.log('localeMessage', localeMessage);
    return localeMessage;
};

export default {
    async initLanguage(userLang) {
        // 用户设置的语言> 用户信息上次设置的语言> 浏览器的语言> 默认语言
        const navLang = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
        const localLang = (navLang === 'zh-CN' || navLang === 'en-US' || navLang === 'ja-JP') ? navLang : false;
        const cacheLang = localStorage.getItem('lang');
        // const userInfoLang = store.getters.locale;
        const lang = userLang || cacheLang || localLang || 'zh-CN';
        Vue.use(VueI18n);
        // 懒加载element语言包
        const elementLangs = await loadElementLang([lang]);
        // 加载本地模块语言包
        const componentsLang = await loadModuleLang.then(res => res);

        // LangVersion = RemoteLangCache ? LangVersion : 0;
        // 懒加载远端语言包 const messages = store.getters.langInfo;
        // 需要加载的语言包信息
        const appArr = [
            {
                versionCode: 0,
                langCode: lang,
                applicationCode: 'APP201904230022', // 公共组件的语言统一从统一权限平台取
                // 此为组件库语言包命名空间
                nameSpace: 'component',
                langInfo: {}
            },
            {
                versionCode: 0,
                langCode: lang,
                applicationCode: 'APP202304140001', // 当前应用
                // 网站业务多语言包名空间
                nameSpace: 'lang',
                langInfo: {}
            }
        ];
        // 加载服务器多语言信息
        const langInfo = await getServerLangInfo(appArr, lang);

        localStorage.setItem('lang', lang);
        // console.log('lang', lang);
        // 合并所有语言
        const messages = store.getters.langInfo || {};
        messages[lang] = Object.assign(
            {},
            { components: componentsLang[componentLangMap[lang]] }, // 本地代码硬编码语言包
            elementLangs[lang], // elelment ui 语言包
            langInfo // 加载接口的远端语言包
        );
        // 放到store让外部I18n类使用
        store.commit('updateLangInfo', messages);
        store.commit('updateLocale', lang);
        return messages[lang];
    }
};

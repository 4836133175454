<template>
    <el-dialog
        title="供应商证件资料预警"
        :visible.sync="dialogVisible"
        width="40%">
        <p>{{supplierNameCn}} 供应商，</p>
        <p>&emsp;&emsp;你好，证件缺失或过期将影响使用，请及时维护！</p>
        <div v-if="corporateList.length > 0">
            <p>供应商默认资质证件：</p>
            <p v-for="(item, index) in corporateList" :key="'info1' + index">&emsp;&emsp;{{item.warnObject}} 的 {{item.qualificationName}} {{item.warnType}}</p>
        </div>
        <div v-if="driverAvehicleList.length > 0">
            <p>司机/车辆默认资质证件：</p>
            <p v-for="(item, index) in driverAvehicleList" :key="'info2' + index">&emsp;&emsp;{{item.warnObject}} 的 {{item.qualificationName}} {{item.warnType}}</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import { getSupplierQualifications } from '@/api/userApi';
const SUPPLIER_QUALIFICATION = '供应商资质';
// const VEHICLE_QUALIFICATION = '车辆资质';
// const DRIVER_QUALIFICATION = '司机资质';
export default {
    computed: {
        ...mapGetters(['user', 'currentTenant'])
    },
    data() {
        return {
            supplierNameCn: '',
            dialogVisible: false,
            corporateList: [],
            driverAvehicleList: []
        };
    },
    methods: {
        initQualifications() {
            const userCode = this.user.userCode.toLocaleLowerCase();
            if (userCode.indexOf('hc') === 0 || userCode.indexOf('hp') === 0) {
                // 获取供应商预警信息
                getSupplierQualifications(this.currentTenant.tenantCode).then(resQualifications => {
                    if (resQualifications && +resQualifications.code === 0) {
                        const qualifications = resQualifications.data;
                        this.supplierNameCn = qualifications[0].supplierNameCn;
                        this.dialogVisible = true;
                        qualifications.forEach(element => {
                            if (element.qualificationType === SUPPLIER_QUALIFICATION) {
                                this.corporateList.push(element);
                            } else {
                                this.driverAvehicleList.push(element);
                            }
                        });
                    }
                });
            }
        }
    },
    created() {
        this.initQualifications();
    }
};
</script>

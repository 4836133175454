import _ from 'lodash';
import zh from './zh-CN';
import en from './en-US';
import ja from './ja-JP';
// 默认的国际化配置
const defaultLang = {
    zh, en, ja
};
// 需要进行国际化的模块
const modules = ['mdm'];
// 重新格式化各个模块的页面名称，格式为: [pageName] => [modName]-[pageName]
function reFormatPageKey(lang, modName) {
    Object.keys(lang).forEach(locale => {
        lang[locale] = _.mapKeys(lang[locale], (value, key) => {
            return `${modName}-${key}`;
        });
    });
    return lang;
}
// 加载各个模块的lang文件
function loadModuleLang(modules) {
    const ps = _.map(modules, modName => {
        return import(`@/modules/${modName}/lang.js`);
    });
    return Promise.all(ps).then(resArr => {
        let i = 0;
        return _.reduce(resArr, (result, res) => {
            const modName = modules[i];
            const lang = reFormatPageKey(res.default, modName);
            i++;
            return _.defaultsDeep(result, lang);
        }, defaultLang);
    });
}
export default loadModuleLang(modules);

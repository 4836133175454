// 合同管理-菜单 L3
const config = {
    'contractManage': { // 合同管理-页面 L4
        'bigData-btn-listPageSearch': '合同管理,合同管理-合同列表,,搜索', // 搜索-按钮 L6
        'bigData-btn-listPageExport': '合同管理,合同管理-合同列表,,导出', // 导出-按钮 L6
        'bigData-page-detailPage': '合同管理,合同管理-合同详情页,,', // 合同管理-合同详情页 L4
        'bigData-btn-detailPageApplyIflow': '合同管理,合同管理-合同详情页,,提交审批', // 提交审批-按钮 L6
        'bigData-btn-detailPageReplenish': '合同管理,合同管理-合同详情页,,补充协议', // 补充协议-按钮 L6
        'bigData-btn-detailPageStopContract': '合同管理,合同管理-合同详情页,,终止协议', // 终止协议-按钮 L6
        'bigData-btn-detailPageOtherChange': '合同管理,合同管理-合同详情页,,其他变更', // 其他变更-按钮 L6
        'bigData-btn-detailPageCustomerTabImport': '合同管理,合同管理-合同详情页,客户明细子页签,导入', // 导入-按钮 L6
        'bigData-page-detailPage-priceDetail': '合同管理,合同管理-合同详情页,价格表子页签,' // 合同管理-合同详情页-价格表子页签 L5
    }
};

export default config;

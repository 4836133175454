<template>
    <div class="root-panel">
        <keep-alive><router-view/></keep-alive>
    </div>
</template>

<script>

export default {
    name: 'Root'
};
</script>
<style lang = 'less'>
.root-panel {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
}
.el-table th.el-table__cell {
    background-color: #f5f6f7;
}
</style>

import axios from 'axios';
import _ from 'lodash';
import NProgress from 'nprogress';
import { lcpMessage as Message } from './lcpMessage.js';
import { logout } from './index';
import config from '../config/user.env';
import store from '../store';
import { AddResourceMessageToReq } from '@annto/tools/dist/src/http/request.mjs';
axios.interceptors.request.use(AddResourceMessageToReq(store, { applicationCode: 'APP202304140001' }));
const RESPONSE_STATUS = {
    OK: 200,
    NOT_LOGIN: 401,
    NO_PERMISSON: 403,
    NOT_FOUND: 404,
    SERVER_ERROR: 500,
    SERVER_TIME_OUT: 504
};
const MILLISECOND_PER_MINUTE = 60000;
const MINUTES = 30;
const PAUSE_SECONDS = 3;

const safeRandom = () => {
    const random = new Uint32Array(1);
    // eslint-disable-next-line no-magic-numbers
    return crypto.getRandomValues(random)[0] / 0xffffffff;
};
axios.defaults.timeout = MILLISECOND_PER_MINUTE * MINUTES; // 请求超时时间
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.apiUrl;

axios.jsonp = (url, data) => { // jsonp跨域封装
    if (!url) { throw new Error('url is necessary'); }
    const strStart = 9;
    const strLength = 18;
    const callback = 'CALLBACK' + safeRandom().toString().substr(strStart, strLength);
    data.callback = callback;
    const JSONP = document.createElement('script');
    JSONP.setAttribute('type', 'text/javascript');
    const headEle = document.getElementsByTagName('head')[0];
    let ret = '';
    if (data) {
        if (typeof data === 'string') {
            ret = '&' + data;
        } else if (typeof data === 'object') {
            // eslint-disable-next-line guard-for-in
            Object.keys(data).forEach((key) => {
                ret += '&' + key + '=' + encodeURIComponent(data[key]);
            });
        }
        ret += '&_time=' + Date.now();
    }
    JSONP.src = `${url}?callback=${callback}${ret}`;
    return new Promise((resolve, reject) => {
        window[callback] = r => {
            resolve(r);
            headEle.removeChild(JSONP);
            delete window[callback];
        };
        headEle.appendChild(JSONP);
    });
};

// request拦截器
axios.interceptors.request.use(config => {
    NProgress.start();
    const currentTenant = store.state.user.currentTenant;
    const tenantCode = currentTenant ? currentTenant.tenantCode : null;
    if (tenantCode) {
        config.headers['tenantCode'] = tenantCode;
    }
    // 过滤get请求的空参数（null, undefined, ''）
    config.params = _.chain(config).get('params').toPairs().map(arr => _.map(arr, _.trim)).filter(arr => !_.isNil(arr[1]) && arr[1] !== '').fromPairs().value();
    return config;
}, error => {
    NProgress.done();
    Promise.reject(error);
});

// respone拦截器
axios.interceptors.response.use(
    response => {
        NProgress.done();
        if (response.data && response.data.code === RESPONSE_STATUS.NO_PERMISSON) {
            logout();
        } else if (response.data && response.data.code && response.data.code !== '0' && +response.data.code !== RESPONSE_STATUS.OK) {
            // 200 为了 oss 接口添加
            Message.error({
                message: response.data.msg,
                showClose: true
            });
            window._paq && window._paq.push(['trackEvent', 'BackEndError', 'error', response.data.msg]);
            return response.data;
        } else {
            return response.data;
        }
    },
    /* eslint-disable  */
    error => {
        NProgress.done();
        const response = Object.assign({}, error.response);
        if (response) {
            switch (response.status) {
                case RESPONSE_STATUS.NOT_LOGIN:
                    Message.closeAll();
                    // Message.error('你还没有登录呦！');
                    // 登出节流
                    if (window.t) {
                        clearTimeout(window.t);
                    }
                    window.t = setTimeout(function () {
                        logout();
                    }, PAUSE_SECONDS * 1000);
                    break;
                case RESPONSE_STATUS.NO_PERMISSON:
                    Message.closeAll();
                    Message.error('登录鉴权失效！请重新登录或找系统管理员授权再试');
                    break;
                case RESPONSE_STATUS.NOT_FOUND:
                    Message.error('没找到接口信息');
                    window._paq && window._paq.push(['trackEvent', 'NetworkError', 'error', '404']);
                    break;
                case RESPONSE_STATUS.SERVER_ERROR:
                    Message.error(`系统异常:${response.status}`);
                    window._paq && window._paq.push(['trackEvent', 'NetworkError', 'error', '500']);
                    break;
                case RESPONSE_STATUS.SERVER_TIME_OUT:
                    Message.error('请求超时');
                    window._paq && window._paq.push(['trackEvent', 'NetworkError', 'error', '504']);
                    break;
                default:
                    Message.error(`系统异常:${response.status}`);
                    window._paq && window._paq.push(['trackEvent', 'NetworkError', 'error', response.status + '']);
            }
        }
        return Promise.reject(error);
    }
);

export default axios;

/**
 * @description 返回子模块有权限的路由
 * @param {String} modName 模块名
 */
import _ from 'lodash';
import store from '@/store';
// import { lcpMessage as Message } from '../lcpMessage.js';

let ROUTERLIMIT = 3;
let MODLENGTH = 0;
let ROUTELENTH = 2;
const MENURESOURCE = 1;
if (window.__MICRO_APP_ENVIRONMENT__) {
    ROUTERLIMIT++;
    MODLENGTH++;
    ROUTELENTH++;
}

// 页面路径验证
function validateRoute(url) {
    const pathArr = _.chain(url).trim().trimStart('/').split('/').value();
    if (pathArr.length !== ROUTERLIMIT) return false;
    if (_.lowerCase(pathArr[MODLENGTH]) !== 'mod') return false;
    return pathArr;
}
// 根据用户权限生成相应的模块路由表
function genrateModRouterMap(menus, result = {}) {
    _.reduce(menus, (routerMap, menu) => {
        const frontUrl = _.get(menu, 'frontUrl', '');
        if (frontUrl && (frontUrl !== '/')) {
            const arr = validateRoute(frontUrl);
            if (arr === false) {
                // Message.warning(`${_.get(menu, 'resourceName')}路径配置不符合规则！`);
            } else {
                const modName = _.camelCase(arr[MODLENGTH + 1]);
                const routeName = _.camelCase(arr[ROUTELENTH]);
                routerMap[modName] = _.isArray(routerMap[modName]) ? routerMap[modName] : [];
                routerMap[modName].push({
                    name: routeName,
                    path: frontUrl,
                    meta: {
                        resourceName: menu.resourceName
                    }
                });
            }
        } else if (menu.resourceType === MENURESOURCE && _.get(menu, 'subResources', []).length > 0) {
            genrateModRouterMap(menu.subResources, routerMap);
        }
        return routerMap;
    }, result);
    return result;
}
const getRoutes = modName => {
    const menus = store.getters.authority;
    const routerMap = genrateModRouterMap(menus);
    return new Promise(resolve => {
        resolve(routerMap[modName]);
    });
};

export {
    getRoutes
};

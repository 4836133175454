import {
    getUserAllTenants,
    setTenant
} from '@/api/userApi';

export default {
    state: {
        tenantDialog: {
            tenantsList: [],
            currentPage: 1,
            total: 0,
            pageSize: 10
        }
    },
    getters: {
        tenantDialog: state => state.tenantDialog
    },
    mutations: {
        updateTenantDialog(state, data) {
            state.tenantDialog = data;
        }
    },
    actions: {
        /**
         * 设置租户
         */
        settingTenant({ dispatch, commit, state }, data) {
            return setTenant(data).then(res => {
                if (res && res.data) {
                    commit('updateCurrenTenant', res.data);
                    return res;
                }
            });
        },
        /**
         * 获取租户列表
         */
        getAllTenants({ dispatch, commit, state }, data) {
            commit('updateTenantDialog', { ...state.tenantDialog, ...data });
            getUserAllTenants({
                tenantCodeOrName: state.tenantDialog.tenantCodeOrName,
                listFlag: 1,
                pageNo: state.tenantDialog.currentPage,
                pageSize: state.tenantDialog.pageSize
            }).then(res => {
                if (res && res.data && res.data.tenants && res.data.tenants.list) {
                    commit('updateTenantDialog', {
                        ...state.tenantDialog,
                        ...{
                            tenantsList: res.data.tenants.list,
                            total: res.data.tenants.totalCount
                        }
                    });
                }
            });
        }
    }
};

<template>
    <el-container class="app-wrap">
        <el-aside class="app-side" width="auto">
            <side-bar></side-bar>
        </el-aside>
        <el-container>
            <el-header height="50px" class="app-header">
                <header-info :comment-monthly="true" :comment-daily="true">
                </header-info>
            </el-header>
            <el-main class="app-main">
                <keep-alive :exclude="notAlivePageNamesArr">
                    <router-view></router-view>
                </keep-alive>
            </el-main>
        </el-container>
        <warn-alert></warn-alert>
        <FeedBack />
    </el-container>
</template>
<script>
import SideBar from '@/components/core/SideBar';
import HeaderInfo from '@/components/core/HeaderInfo';
import WarnAlert from '@/components/core/WarnAlert';
import { notAlivePageNames, changeFlag } from '@/utils/keepAliveCfg.js';
import FeedBack from '@/components/Feedback/index';
export default {
    name: 'app',
    components: { SideBar, HeaderInfo, WarnAlert, FeedBack },
    data() {
        return {
            notAlivePageNames,
            changeFlag
        };
    },
    computed: {
        notAlivePageNamesArr() {
            return this.changeFlag.flag ? [...this.notAlivePageNames] : [];
        }
    }
};
</script>
<style lang="less">
// 滚动条相关配置
@scrollbarThumbColor: rgba(0,0,0, .1);
@scrollbarThumbColorHover: rgba(0,0,0, .4);
::-webkit-scrollbar {
    width:6px;
    height:6px;
}
// 滚动块样式
::-webkit-scrollbar-thumb {
    background-color:@scrollbarThumbColor;
    background-clip:padding-box;
    min-height:28px;
    border-radius:2em;
}
::-webkit-scrollbar-thumb:hover {
        background-color:@scrollbarThumbColorHover;
}
.app-wrap {
    height: 100%;
    .app-side {
        background: #1e1e28;
    }
    .app-header {
        padding: 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .1);
        position: relative;
        z-index: 99;
    }
    .app-main {
        padding: 10px;
        background: #f3f4f6;
        display: flex;
    }
}
.el-dropdown-menu {
    .el-dropdown-menu__item {
        .dropdown-item-span {
            color: #4285f5;
        }
        &.dropdown-item {
            width: 60px;
            &:hover {
                background-color: #ecf5ff;
                .dropdown-item-span {
                    color: #4285f5;
                }
            }
        }
    }
}
.el-table th.el-table__cell {
    background-color: #f5f6f7;
}
</style>

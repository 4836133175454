
import VueI18n from 'vue-i18n';
import Vue from 'vue';
import store from '@/store';
Vue.use(VueI18n);
let Instance = null;
const I18n = function() {
    // 单例
    if (Instance === null) {
        Instance = new VueI18n({
            locale: store.getters.locale || 'zh-CN',
            messages: store.getters.langInfo || {},
            numberFormats: {
                'zh-CN': {
                    currency: {
                        style: 'decimal', currency: 'USD'
                    }
                }

            },
            // 当key翻译不成功，使用制定语言代替
            fallbackLocale: 'zh-CN',
            silentTranslationWarn: true
        });
    }
    return Instance;
};

const $translate = function(key, params) {
    // 判断语言包key是否存在
    const hasKey = I18n().te(key);
    // 获取翻译内容
    const translatedContent = I18n().t(key, params);
    if (hasKey && translatedContent) {
        return translatedContent;
    } else if (params) {
        if (params.defaultText) {
            return params.defaultText;
        } else {
            return key;
        }
    } else {
        return key;
    }
};
const I18nMixins = {
    methods: {
        // 用于处理语言包不存在的时候 使用缺省值
        $translate(key, params) {
            return $translate(key, params);
        }
    }
};

export {
    I18n,
    I18nMixins,
    $translate
};

/**
 *
 * 语言代码 http://www.lingoes.cn/zh/translator/langcode.htm
 *
 * 在非Vue文件使用国际化，如config.js
 * import I18n from '@/utils/i18n';
 * {
        'label': I18n().t('lang.key1'),
        'prop': 'code',
        'width': 200
    }
 <el-table-column
 prop="id"
 :label="$t.('tableColumn.columnName1')"
 width="180">
 *
 *
 *
 * 在Vue内部使用国际化
 * 1.在html中引用     <el-button>{{$t.('lang.key')}}</el-button>
 * 2.在标签中使用      <el-form-item label="$t.('lang.key')" ></el-form-item>
 * 3.在VUE的js中调用   this.$t('lang.key1')
 *
 * 在配置的js文件内 注意、
 * 正确示例
 * tableColumn={
 *   columnName1:lang.key1,
 *   columnName2:lang.key3,
 * }
 * <el-table-column
 prop="id"
 :label="$t.('tableColumn.columnName1')"
 width="180">
 * 错误示范
 * tableColumn={
 *   columnName1:this.t('lang.key1'),
 *   columnName2:this.t('lang.key2'),
 * }
 * <el-table-column
 prop="id"
 :label="tableColumn.columnName1"
 width="180">
 */
